import { Skeleton } from 'primereact/skeleton';

export default function SelectionControlsSkeleton() {
  return (
    <div className='flex flex-col gap-2'>
      <Skeleton height='1.0rem' width='100px' className='overflow-hidden' />
      <Skeleton height='1.3rem' width='150px' className='overflow-hidden' />
    </div>
  );
}
