import React, { useMemo, useState } from 'react';
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';

import DndPickListSublist from './DndPickListSublist';
import DndPickListTransferControls from './DndPickListTransferControls';
import DndPickListItem from './DndPickListItem';

export type PickListItem = { [key: string]: any };
export type Items = { target: PickListItem[]; source: PickListItem[] };

export interface DndPickListChangeEvent {
  originalEvent: any;
  items: Items;
}

export interface DndPickListTransferEvent {
  originalEvent: any;
  items: Items;
  movedItems: PickListItem[];
  direction: Direction;
}

export interface DndPickListInsertEvent {
  originalEvent: any;
  items: Items;
  movedItems: PickListItem[];
  index: number;
  direction: Direction;
}

export interface DndPickListShiftEvent {
  originalEvent: any;
  items: Items;
  movedItems: PickListItem[];
  direction: Direction;
  index: number;
}

export interface DndPickListProps {
  idField: string;
  displayField: string;
  items: Items;
  targetLimit?: number;
  isLoading?: boolean;
  disabled?: boolean;
  targetDisabled?: boolean;
  sourceDisabled?: boolean;
  sourceHeader?: string | React.ReactNode;
  targetHeader?: string | React.ReactNode;
  onChange: (args: DndPickListChangeEvent) => void;

  // Optional event handlers.
  //  These are not currently implemented, but can be if the default behavior is not sufficient.
  onMoveToSource?: (args: DndPickListTransferEvent) => void;
  onMoveAllToSource?: (args: DndPickListTransferEvent) => void;
  onMoveToTarget?: (args: DndPickListTransferEvent) => void;
  onMoveAllToTarget?: (args: DndPickListTransferEvent) => void;
  onInsertIntoTarget?: (args: DndPickListInsertEvent) => void;
  onInsertIntoSource?: (args: DndPickListInsertEvent) => void;
  onShiftSource?: (args: DndPickListShiftEvent) => void;
  onShiftTarget?: (args: DndPickListShiftEvent) => void;
}

export type Direction = 'source' | 'target';

const TARGET_LIST_ID = 'target-list';
const SOURCE_LIST_ID = 'source-list';

export default function DndPickList(props: DndPickListProps) {
  let {
    idField,
    displayField,
    onChange,
    targetLimit,
    items,
    sourceHeader,
    targetHeader,
    disabled = false,
    isLoading = false,
    targetDisabled = false,
    sourceDisabled = false,
  } = props;

  // Selection manages the items that have been selected for transfer between lists. Target and source are mutually exclusive.
  const [selection, setSelection] = useState<{ target: PickListItem[]; source: PickListItem[] }>({
    target: [],
    source: [],
  });

  // Active item is the item that is currently being dragged and is rendered as a drag overlay.
  const [activeItemId, setActiveItemId] = useState<string | null>(null);

  // Active item is used to add to lists when dragging over them.
  const activeItem: PickListItem | undefined = useMemo(() => {
    if (!activeItemId) {
      return undefined;
    }
    return [...items.source, ...items.target].find((item) => item[idField] === activeItemId) || undefined;
  }, [activeItemId, items, idField]);

  // Find the direction of the item based on its id.
  function findDirection(itemId: string): Direction | undefined {
    // The itemId can be equal to the constant values we use to represent the lists. This is the
    // case when we are dragging an item over the root of a list.
    if (items.source.find((item) => item[idField] === itemId) || itemId === SOURCE_LIST_ID) {
      return 'source';
    }

    if (items.target.find((item) => item[idField] === itemId) || itemId === TARGET_LIST_ID) {
      return 'target';
    }
    return undefined;
  }

  // Find the item based on its id. Picks from both source and target lists.
  function findItem(itemId: string): PickListItem | undefined {
    return [...items.source, ...items.target].find((item) => item[idField] === itemId);
  }

  // Used for items to determine if they are selected.
  function getIsSelected(itemId: string): boolean {
    if (Object.values(selection).find((item) => item.find((i) => i[idField] === itemId))) {
      return true;
    }
    return false;
  }

  // Add/remove items to their respective selection lists.
  function onItemClick(itemId: string): void {
    const direction = findDirection(itemId);

    if (!direction) return;

    const item = findItem(itemId);
    if (!item) return;

    if (direction === 'source') {
      setSelection((prev) => {
        return {
          target: [],
          source: prev.source.find((i) => i[idField] === itemId)
            ? [...prev.source.filter((i) => i[idField] !== itemId)]
            : [...prev.source, item],
        };
      });
    } else if (direction === 'target') {
      setSelection((prev) => {
        return {
          source: [],
          target: prev.target.find((i) => i.id === itemId)
            ? [...prev.target.filter((i) => i.id !== itemId)]
            : [...prev.target, item],
        };
      });
    }
  }

  function clearSelection(): void {
    setSelection({ target: [], source: [] });
  }

  // Initiates drag events and sets the actively dragged item.
  function handleDragStart(event: DragStartEvent): void {
    const activeId = String(event.active.id);
    if (!activeId) return;

    clearSelection();

    setActiveItemId(activeId);
  }

  // Handles the drag over event. This allows us to drag a draggable over another sortable list and have it
  // insert in the other list at the correct index without having to drop it. This manages transfers between lists.
  // For intra-list sorting, we use the dragEnd event (see below).
  function handleDragOver(event: DragOverEvent): void {
    const { over, active } = event;

    if (!active || !over || !activeItem) return;

    const activeId = String(active.id);
    const overId = String(over.id);

    if (activeId === overId) return;

    const activeDirection = findDirection(activeId);
    const overDirection = findDirection(overId);

    // If we are missing directions or the drag has occurred within the same list, we do not need to do anything.
    if (!activeDirection || !overDirection || activeDirection === overDirection) return;

    // If we are over the target list and the target limit has been reached, we do not allow any more items to be added.
    if (overDirection === 'target' && targetLimit && items.target.length >= targetLimit) return;
    if (disabled || (overDirection === 'source' && sourceDisabled) || (overDirection === 'target' && targetDisabled))
      return;

    // ⚠️
    // NOTE: We can use the following logic because we have determined earlier that the active and over directions are different.
    // However: As is the case in the onDropEvent function below, the active and over direction would be the same and this logic would not work.

    // These lists are used to store the altered items for the lists that is being hovered over and the list it is coming from.
    // AlteredOverDirection is the list that is being hovered over and alteredActiveDirection is the list that is being dragged from.
    let alteredOverDirection: PickListItem[] = [];
    let alteredActiveDirection: PickListItem[] = [];

    // Remove the item from the list it is coming from.
    alteredActiveDirection = items[activeDirection].filter((item) => item[idField] !== activeId);

    // If activeId is a list id, is is being hovered the root of a list (This happens when at the bottom). We add the active item to the end of the list.
    if (overId === SOURCE_LIST_ID || overId === TARGET_LIST_ID) {
      alteredOverDirection = [...items[overDirection], activeItem];
    } else {
      const overIndex = items[overDirection].findIndex((item) => item[idField] === overId);
      if (overIndex === -1) return;

      // Insert the active item at the index of the item we are hovering over.
      alteredOverDirection = [
        ...items[overDirection].slice(0, overIndex),
        activeItem,
        ...items[overDirection].slice(overIndex),
      ];
    }

    onChange({
      originalEvent: event,
      // Do not try using [overDirection] or [activeDirection] to access keys here. Typescript will deem it as a string and not of the Direction type.
      items: {
        source: overDirection === 'source' ? alteredOverDirection : alteredActiveDirection,
        target: overDirection === 'target' ? alteredOverDirection : alteredActiveDirection,
      },
    });
  }

  // Handles the drag end event. This is where we actually move the items in the list. This is used to reorder sortables when they are being moved
  // within the same container.
  function handleDragEnd(event: DragEndEvent): void {
    setActiveItemId(null);
    const { over, active } = event;

    if (!over || !active || !activeItem) return;

    const activeId = String(active.id);
    const overId = String(over.id);

    const activeDirection = findDirection(activeId);
    const overDirection = findDirection(overId);

    if (!activeDirection || !overDirection || activeDirection !== overDirection) return;

    const overIndex = items[overDirection].findIndex((item) => item[idField] === overId);
    const activeIndex = items[overDirection].findIndex((item) => item[idField] === activeId);

    // List with the shifted items.
    const alteredList = arrayMove([...items[overDirection]], activeIndex, overIndex);

    // The direction of the list that is not being hovered within
    const oppositeDirection = overDirection === 'source' ? 'target' : 'source';

    onChange({
      originalEvent: event,
      items: {
        // Do not try using [overDirection] or [activeDirection] here to access properties. Typescript will deem it as a string and not of the Direction type.
        target:
          // If over direction is target, we shift the items in the target list. Else we just copy the target list.
          overDirection === 'target' ? alteredList : [...items[oppositeDirection]],
        source: overDirection === 'source' ? alteredList : [...items[oppositeDirection]],
      },
    });
  }

  // Creates sensor that only activates when the mouse moves a certain distance
  // This ensures the dragStart does not start if users only click on the template
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });

  const sensors = useSensors(mouseSensor);

  return (
    <DndContext onDragEnd={handleDragEnd} onDragStart={handleDragStart} sensors={sensors} onDragOver={handleDragOver}>
      <div className='flex items-center justify-between gap-3 w-full h-full'>
        {/* list of available templates */}
        <div className='flex-1'>
          <DndPickListSublist
            id={SOURCE_LIST_ID}
            direction='source'
            activeItemId={activeItemId}
            displayField={displayField}
            getIsSelected={getIsSelected}
            idField={idField}
            selection={selection}
            items={items.source}
            onClick={onItemClick}
            isLoading={isLoading || false}
            targetLimit={targetLimit}
            disabled={disabled || sourceDisabled}
            header={sourceHeader}
          />
        </div>

        {/* transfer controls */}
        <div className='flex flex-col flex-0 h-full items-center justify-center'>
          <DndPickListTransferControls
            clearSelection={clearSelection}
            items={items}
            selection={selection}
            onChange={onChange}
            targetLimit={targetLimit}
            disabled={disabled}
            targetDisabled={targetDisabled}
            sourceDisabled={sourceDisabled}
          />
        </div>

        {/* list of selected templates */}
        <div className='flex-1'>
          <DndPickListSublist
            activeItemId={activeItemId}
            displayField={displayField}
            direction='target'
            getIsSelected={getIsSelected}
            idField={idField}
            selection={selection}
            isLoading={isLoading || false}
            id={TARGET_LIST_ID}
            items={items.target}
            onClick={onItemClick}
            targetLimit={targetLimit}
            disabled={disabled || targetDisabled}
            header={targetHeader}
          />
        </div>
      </div>

      {/* drag overlay allows for dragging between droppables and improves appearance with overflow containers.
      It utilizes a copy of the active item. The list item itself disappears or becomes a ghost.*/}
      <DragOverlay>
        {activeItem && activeItemId ? (
          <DndPickListItem
            id={activeItem[idField]}
            activeItemId={activeItemId}
            direction={'source'}
            displayField={displayField}
            getIsSelected={getIsSelected}
            idField={idField}
            isOverlay={true}
            item={activeItem}
            onClick={onItemClick}
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
}
