import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ObjectTypeQuickCreateData, QuickCreateData } from './settingsApi';
import { RootState } from '../../store';

/**
 * Settings slice state is stored so that components can share state without having to wait updates from the server.
 * We are currently using auto-save so update are debounced and changes are not immediately reflected in the UI.
 * This state is used to immediately update the UI and provide a better user experience.
 */
export interface SettingsSliceState {
  data: {
    quickCreate: QuickCreateData;
  };
}

const initialState: SettingsSliceState = {
  data: {
    quickCreate: {
      enabled: true,
    },
  },
};

export interface SetObjectTypeQuickCreateArgs {
  objectTypeName: string;
  data: ObjectTypeQuickCreateData;
}

export interface SetTemplateIdsArgs {
  objectTypeName: string;
  templateIds: string[];
}

export interface ToggleQuickCreateArgs {
  objectTypeName?: string;
}

export interface ToggleUserDefinedArgs {
  objectTypeName: string;
}

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setQuickCreate(state, action: PayloadAction<QuickCreateData>) {
      state.data.quickCreate = action.payload;
    },

    setTemplateIds(state, action: PayloadAction<SetTemplateIdsArgs>) {
      const { objectTypeName, templateIds } = action.payload;

      const objectTypeQuickCreateData = state.data.quickCreate[objectTypeName];

      if (objectTypeQuickCreateData === undefined || typeof objectTypeQuickCreateData === 'boolean') {
        state.data.quickCreate[objectTypeName] = { templateIds, enabled: true, userDefined: true };
        return;
      }

      state.data.quickCreate[objectTypeName] = {
        ...objectTypeQuickCreateData,
        templateIds,
        userDefined: true,
      };
    },

    // These reducers are not currently used.

    // Toggle enabled status of quick create. If objectTypeName is provided, it will toggle the enabled status of that object type
    toggleQuickCreate(state, action: PayloadAction<ToggleQuickCreateArgs>) {
      // When no objectTypeName is provided, toggle the global quick create enabled status
      if (!action.payload.objectTypeName) {
        state.data.quickCreate.enabled = !state.data.quickCreate.enabled;
        return;
      }

      // Else, toggle the enabled status of the object type
      const objectTypeQuickCreateData = state.data.quickCreate[action.payload.objectTypeName];

      if (!objectTypeQuickCreateData || typeof objectTypeQuickCreateData === 'boolean') {
        state.data.quickCreate[action.payload.objectTypeName] = { enabled: true, templateIds: [], userDefined: true };
        return;
      }

      objectTypeQuickCreateData.enabled = !objectTypeQuickCreateData.enabled;
    },

    // Toggle user defined sets the userDefined property to the opposite of what it currently is. It requires an objectTypeName to make the change
    toggleUserDefined(state, action: PayloadAction<ToggleUserDefinedArgs>) {
      const objectTypeQuickCreateData = state.data.quickCreate[action.payload.objectTypeName];

      if (!objectTypeQuickCreateData || typeof objectTypeQuickCreateData === 'boolean') {
        state.data.quickCreate[action.payload.objectTypeName] = { enabled: true, templateIds: [], userDefined: true };
        return;
      }

      objectTypeQuickCreateData.userDefined = !objectTypeQuickCreateData.userDefined;
    },
  },
});

export const { setQuickCreate, toggleQuickCreate, setTemplateIds, toggleUserDefined } = settingsSlice.actions;

export const selectQuickCreate = (state: RootState) => state.settings.data.quickCreate;

export default settingsSlice.reducer;
