import { useCallback, useEffect, useMemo } from 'react';
import {
  ObjectTypeQuickCreateData,
  QuickCreateData,
  useGetSettingsQuery,
  useUpdateSettingsMutation,
} from '../settingsApi';
import { useObjectTypes } from '../../objectTypes';
import { useDispatch, useSelector } from 'react-redux';
import { selectQuickCreate, setQuickCreate as _setQuickCreate } from '../settingsSlice';
import _ from 'lodash';

export interface UseQuickCreateReturnType {
  quickCreateData: QuickCreateData | undefined;
  objectTypeQuickCreateData: ObjectTypeQuickCreateData | undefined;
  updateMetaData: ReturnType<typeof useUpdateSettingsMutation>[1];

  setQuickCreate: (data: QuickCreateData) => void;
  setTemplateIds: (args: { data: QuickCreateData; templateIds: string[]; objectTypeName: string }) => void;
  toggleQuickCreate: (args: { data: QuickCreateData; objectTypeName?: string }) => void;
  toggleUserDefined: (args: { data: QuickCreateData; objectTypeName: string }) => void;
  saveQuickCreate: (data: QuickCreateData) => void;
}

export interface UseQuickCreateArgs {
  // The fixed cache key to use for the settings query. Allows for components to share update meta data.
  fixedCacheKey?: string;
  // The delay in milliseconds to wait before updating the settings. Components can use this to flexibly control the update rate.
  updateDelay?: number;
}

export function useQuickCreate({ fixedCacheKey, updateDelay = 1000 }: UseQuickCreateArgs): UseQuickCreateReturnType {
  const [updateSettings, updateMetaData] = useUpdateSettingsMutation({ fixedCacheKey });
  const settingsResponse = useGetSettingsQuery();

  const { selectedObjectType } = useObjectTypes();

  const dispatch = useDispatch();

  const quickCreateData = useSelector(selectQuickCreate);

  // Extract the selected object type's quick create data
  const objectTypeQuickCreateData: ObjectTypeQuickCreateData | undefined = useMemo(() => {
    if (!quickCreateData || !selectedObjectType) return undefined;

    const objectTypeQuickCreateData = quickCreateData[selectedObjectType.objectTypeName];
    if (typeof objectTypeQuickCreateData === 'boolean') return undefined;

    return objectTypeQuickCreateData;
  }, [quickCreateData, selectedObjectType]);

  // Debounce the updateSettings call to prevent too many requests
  const debounceUpdate = useMemo(
    () =>
      _.debounce((data: QuickCreateData) => {
        updateSettings({ quickCreateData: data });
      }, updateDelay),
    [updateSettings, updateDelay],
  );

  // Set state of quick create data
  const setQuickCreate = useCallback(
    (data: QuickCreateData) => {
      dispatch(_setQuickCreate(data));
    },
    [dispatch],
  );

  // Used to save quick create data to the server
  const saveQuickCreate = useCallback(
    (data: QuickCreateData) => {
      setQuickCreate(data);
      debounceUpdate(data);
    },
    [debounceUpdate, setQuickCreate],
  );

  // Add template ids to the selected object type's quick create data
  const setTemplateIds = useCallback(
    ({
      data,
      objectTypeName,
      templateIds,
    }: {
      data: QuickCreateData;
      templateIds: string[];
      objectTypeName: string;
    }) => {
      const dataCopy = _.cloneDeep(data);

      const objectTypeQuickCreateData = data[objectTypeName];
      dataCopy[objectTypeName] =
        !objectTypeQuickCreateData || typeof objectTypeQuickCreateData === 'boolean'
          ? // If the object type data is not defined, we create it with the provided template ids
            { templateIds, enabled: true, userDefined: true }
          : // Else we update the template ids and set userDefined to true
            { ...objectTypeQuickCreateData, templateIds, userDefined: true };

      saveQuickCreate(dataCopy);
    },
    [saveQuickCreate],
  );

  const toggleQuickCreate = useCallback(
    ({ data, objectTypeName }: { data: QuickCreateData; objectTypeName?: string }) => {
      const dataCopy = _.cloneDeep(data);

      if (!objectTypeName) {
        dataCopy.enabled = !dataCopy.enabled;
      } else {
        const objectTypeQuickCreateData = data[objectTypeName];

        dataCopy[objectTypeName] =
          !objectTypeQuickCreateData || typeof objectTypeQuickCreateData === 'boolean'
            ? { enabled: true, templateIds: [], userDefined: true }
            : { ...objectTypeQuickCreateData, enabled: !objectTypeQuickCreateData.enabled };
      }

      saveQuickCreate(dataCopy);
    },
    [saveQuickCreate],
  );

  const toggleUserDefined = useCallback(
    (args: { data: QuickCreateData; objectTypeName: string }) => {
      const dataCopy = _.cloneDeep(args.data);

      const objectTypeQuickCreateData = dataCopy[args.objectTypeName];
      dataCopy[args.objectTypeName] =
        !objectTypeQuickCreateData || typeof objectTypeQuickCreateData === 'boolean'
          ? { enabled: true, templateIds: [], userDefined: false }
          : { ...objectTypeQuickCreateData, userDefined: !objectTypeQuickCreateData.userDefined };

      saveQuickCreate(dataCopy);
    },
    [saveQuickCreate],
  );

  // Rolls-back state if an error occurred while saving.
  useEffect(() => {
    if ((updateMetaData.isError || updateMetaData.error) && settingsResponse.data?.quickCreateData) {
      setQuickCreate(settingsResponse.data.quickCreateData);
    }
  }, [updateMetaData.isError, updateMetaData.error, settingsResponse.data, setQuickCreate]);

  return {
    quickCreateData,
    saveQuickCreate,
    setTemplateIds,
    updateMetaData,
    toggleUserDefined,
    objectTypeQuickCreateData,
    setQuickCreate,
    toggleQuickCreate,
  };
}
