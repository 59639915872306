import { LoadError } from '@react-pdf-viewer/core';
import { Panel } from 'primereact/panel';

export default function DocumentErrorMessage({ error }: { error: LoadError }) {
  return (
    <div className='w-full max-h-full overflow-y-auto'>
      <div className='flex justify-center '>
        <div className='w-full max-w-xl bg-white rounded-xl p-6 m-10'>
          <div className='flex justify-center gap-3 items-center mb-5 text-red-500'>
            <i className='pi pi-info-circle text-2xl' />
            <h3 className='text-center text-2xl'>Unable to load document</h3>
          </div>

          <div className='flex flex-col gap-2 mb-5 text-md text-neutral-500'>
            <p>An error was encountered while loading the document.</p>
            <p>
              Try refreshing the page. If the error persists please contact{' '}
              <a className='text-blue-500 hover:underline' href='mailto:support@documint.me'>
                Documint Support
              </a>
            </p>
          </div>

          <Panel header='Error details' toggleable className='w-full' collapsed={true}>
            <div className='w-full overflow-x-auto'>
              <p>{error.message}</p>
            </div>
          </Panel>
        </div>
      </div>
    </div>
  );
}
