import { Dialog } from 'primereact/dialog';
import useTemplateUI from '../../hooks/useTemplatesUI';
import { TemplateList } from '.';
import TemplateListHeader from './TemplateListHeader';

export default function TemplateListModal() {
  const { setTemplatesModalVisibility, templateModalVisibility } = useTemplateUI();

  const headerTemplate = <TemplateListHeader />;

  return (
    <Dialog
      onHide={() => setTemplatesModalVisibility(false)}
      pt={{ headerIcons: { className: '!hidden' } }}
      modal
      header={headerTemplate}
      draggable={false}
      style={{ width: '80%', height: '90%' }}
      visible={templateModalVisibility}
    >
      <TemplateList />
    </Dialog>
  );
}
