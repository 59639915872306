/**
 * This list contains the templates that have been saved for quick create. It will appear when the user has
 * selected auto select.
 */

import TooltipIcon from '../../../components/TooltipIcon';
import { Template } from '../../templates';

interface SelectedTemplatesListProps {
  templates: Template[];
}

export default function QuickCreateTemplateList({ templates }: SelectedTemplatesListProps) {
  return (
    <div className='w-[22rem] h-[26.5rem] rounded-md border-[0.5px] border-slate-300 bg-gray-50 mt-2'>
      {/* header */}
      <div className='flex flex-row justify-between items-center w-full p-4 h-[3.5rem] bg-white border-b-[1px] border-b-slate-300 rounded-t-md'>
        <div className='flex flex-row items-center gap-2'>
          <h3 className='text-md font-semibold'>Quick-Create Templates</h3>
          <TooltipIcon
            tooltip={
              'These are the templates that are available for quick-create. In order to edit this list deselect the "auto-select" option.'
            }
            id='template-list-tooltip'
          />
        </div>
      </div>

      {/* content */}
      <div className='flex flex-col h-[23rem] w-full items-center justify-center '>
        <div className='w-full h-[21rem] px-5 overflow-x-hidden overflow-y-auto'>
          <div className='flex flex-col w-full h-full items-center justify-start gap-3'>
            {templates.map(({ name }, index) => (
              <div
                key={index}
                className='z-[1000] bg-white flex flex-row justify-start items-center w-full h-[3.5rem] px-2 rounded-md border-[1px] border-slate-300 shadow-md'
              >
                <p className='w-full text-ellipsis text-nowrap whitespace-nowrap overflow-hidden ml-1'>{name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
