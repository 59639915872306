import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DocumentWorker, DocumentViewerSkeleton, useCreateDocumentMutation } from '../../features/documents';
import DocumentErrorMessage from '../../features/documents/components/DocumentErrorMessage';
import { ErrorResponse } from '../../api/types';
import { Toast } from 'primereact/toast';
import { storeSessionTokenInSession } from '../../api';

export default function Generate() {
  const [error, setError] = useState<ErrorResponse | null>(null);
  const navigate = useNavigate();

  const generateDocToastRef = useRef<Toast>(null);

  const [createDocument, createDocumentMeta] = useCreateDocumentMutation({ fixedCacheKey: 'do-not-call-twice' });

  // Main effect hook that gets the url params and creates the document from them.
  useEffect(() => {
    const search = new URLSearchParams(window.location.search);

    // get the token from the url or session storage
    const urlToken = search.get('session_token');

    // If unauthorized, navigate to the unauthorized page
    if (!urlToken) return setError({ message: '401: Missing session token', status: 401 });

    storeSessionTokenInSession(urlToken);

    const isPreview = search.get('isPreview');

    // If we are missing the data we need to generate the document we should swap the loading indicator for an error message
    const objectId = search.get('associatedObjectId');
    const objectTypeId = search.get('associatedObjectType');
    const templateId = search.get('templateId');

    if (!objectId || !objectTypeId || !templateId) {
      setError({
        message:
          '400: Missing one of three required arguments to generate a document: objectId | objectType | templateId',
        status: 400,
      });
      return;
    }

    // Create the document and show a success toast if successful
    createDocument({
      objectId,
      objectTypeId,
      templateId,
      isLive: !isPreview || false,
    })
      // await the response and show a toast if successful
      .then((response) => {
        if ('error' in response) {
          throw new Error('Error creating document.');
        }
        if (!isPreview) {
          // GenerateDocToastRef will appear twice in the strict mode in development, but should not be an issue in production.
          generateDocToastRef.current?.show({
            severity: 'success',
            summary: 'Document Generated!',
            detail: (
              <>
                <b>{response.data?.name}</b> has been saved to the <b>Notes</b> section of this object.
              </>
            ),
            life: 5000,
          });
        }
      })
      .catch();
  }, [createDocument, navigate, setError]);

  // Checks if the document was created successfully and if so, returns the URL to view the document
  const documentUrl = useMemo(
    () => (createDocumentMeta.isSuccess && createDocumentMeta.data?.url ? createDocumentMeta.data.url : undefined),
    [createDocumentMeta],
  );

  // Check for errors messages and set the error state if necessary
  useEffect(() => {
    if (createDocumentMeta.error) {
      const { status, message } = createDocumentMeta.error as ErrorResponse;

      setError({
        message: `${status}: ${message}`,
        status,
      });
      return;
    }

    // I do not know if this ever happens, but I have seen successful requests with no document appearing.
    if (createDocumentMeta.isSuccess && !createDocumentMeta.data.url) {
      setError({
        message:
          'No URL was returned to view the document, Check record attachments to see if the document was attached. If not, please try regenerating the document.',
        status: 500,
      });
    }
  }, [createDocumentMeta]);

  return (
    <>
      <div
        className='document-viewer w-screen h-screen flex flex-col justify-center items-center py-12'
        style={{ backgroundColor: '#f4f7ff' }}
      >
        {error ? (
          <DocumentErrorMessage error={error} />
        ) : createDocumentMeta.isLoading ? (
          <DocumentViewerSkeleton />
        ) : (
          // The worker is generated if we have successfully received a document URL
          <div className='w-full h-full'>{documentUrl && <DocumentWorker documentUrl={documentUrl} />}</div>
        )}
      </div>
      <Toast ref={generateDocToastRef} position='bottom-left' />
    </>
  );
}
