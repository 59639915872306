import { SelectButton } from 'primereact/selectbutton';
import usePropertiesUi from '../../hooks/usePropertiesUi';
import { DefinedBy } from '../../propertiesSlice';

const DefinedBySelect = () => {
  const {
    propertiesView: { definedBy },
    setPropertiesView,
  } = usePropertiesUi();

  const fieldDefinitionOptions: { value: DefinedBy; name: string }[] = [
    { value: 'all', name: 'All' },
    { value: 'hubspot', name: 'Hubspot' },
    { value: 'user', name: 'Me' },
  ];

  return (
    <SelectButton
      value={definedBy || 'all'}
      options={fieldDefinitionOptions}
      optionLabel='name'
      onChange={(e) => setPropertiesView({ definedBy: e.value })}
      tooltip='Defined by specifies who created the property.'
      tooltipOptions={{ showDelay: 1000, position: 'bottom' }}
    />
  );
};

export default DefinedBySelect;
