import { ExpandedObjectInterface } from '../objectsApi';

export function startsWithVowel(word: string) {
  return RegExp('^[aeiou]', 'i').test(word);
}

export function renderContactTitle(object: ExpandedObjectInterface) {
  const { primaryDisplayProperty, secondaryDisplayProperties = {} } = object || {};
  const { lastname, email } = secondaryDisplayProperties;

  let result = '---';

  if (primaryDisplayProperty || lastname) {
    result = [primaryDisplayProperty, lastname].join(' ');
  } else if (email) {
    result = email;
  }

  return result;
}

export function findContactTitle(object: ExpandedObjectInterface): string {
  const { primaryDisplayProperty, secondaryDisplayProperties = {} } = object || {};
  const { lastname, email } = secondaryDisplayProperties;

  let result = '';

  if (primaryDisplayProperty || lastname) {
    result = [primaryDisplayProperty, lastname].join(' ');
  } else if (email) {
    result = email;
  }

  return result;
}

export function findCompanyTitle(object: ExpandedObjectInterface): string {
  const { primaryDisplayProperty, secondaryDisplayProperties = {} } = object || {};
  const { domain } = secondaryDisplayProperties;

  return primaryDisplayProperty || domain || '';
}

export function findObjectTitle(object: ExpandedObjectInterface): string {
  if (object.objectTypeName === 'CONTACT' || object.objectTypeId === '0-1') {
    return findContactTitle(object);
  }

  if (object.objectTypeName === 'COMPANY' || object.objectTypeId === '0-2') {
    return findCompanyTitle(object);
  }

  return object.primaryDisplayProperty || '';
}
