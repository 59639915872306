import { useCallback, useRef, useState } from 'react';
import { InputSwitch } from 'primereact/inputswitch';
import AssociationInstructions from './AssociationInstructions';
import GroupDropdown from './GroupDropdown';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { SelectItemOptionsType } from 'primereact/selectitem';
import PropertiesSearchInput from './PropertiesSearchInput';
import { OverlayPanel } from 'primereact/overlaypanel';
import DefinedBySelect from './DefinedBySelect';
import styles from './properties-controls.module.css';
import usePropertiesUi from '../../hooks/usePropertiesUi';
import PropertiesDropdown from './PropertiesDropdown';
import PropertiesTabMenuAlt from './PropertiesTabMenu';
import TooltipIcon from '../../../../components/TooltipIcon';

const useTypeOptions: SelectItemOptionsType = [
  { label: 'All items', value: 'loop' },
  { label: 'First item', value: 'first' },
];

export default function PropertiesControlHeader() {
  const [associationInstructionsVisible, setAssociationInstructionsVisible] = useState<boolean>(false);
  const {
    propertiesView: { activePropertyGroup, isFormatted, useType },
    setPropertiesView,
  } = usePropertiesUi();

  const filtersRef = useRef<OverlayPanel>(null);
  const formattersInstructionsRef = useRef<OverlayPanel>(null);

  const toggleIsFormatted = useCallback(
    () => setPropertiesView({ isFormatted: !isFormatted }),
    [isFormatted, setPropertiesView],
  );

  return (
    <>
      <div className='flex h-full w-full items-center gap-4 justify-between'>
        {/*
         LEFT
        */}
        <div className='flex items-center gap-3 justify-start flex-nowrap basis-1/5'>
          {/* input field */}
          <PropertiesSearchInput />

          {/* FILTERS */}
          <Button
            icon='pi pi-sliders-h'
            onClick={(e) => filtersRef?.current?.toggle?.(e)}
            text
            severity='secondary'
            style={{ paddingBlock: 7 }}
          />

          <OverlayPanel ref={filtersRef}>
            <div className='flex flex-col gap-4'>
              <h4>Filters</h4>
              <div className='flex flex-col gap-2 flex-nowrap'>
                <label className='text-sm whitespace-nowrap'>Property group</label>
                <GroupDropdown />
              </div>

              <div className='flex flex-col gap-2 flex-nowrap'>
                <label className='text-sm whitespace-nowrap'>Property defined by</label>
                <DefinedBySelect />
              </div>
            </div>
          </OverlayPanel>
        </div>

        {/*
         CENTER
         */}
        <div className='flex items-center justify-center gap-3 flex-nowrap overflow-hidden max-w-full min-w-[18rem]'>
          {/* <PropertiesTabMenu /> */}
          <div className='w-full hidden xl:block'>
            <PropertiesTabMenuAlt />
          </div>

          {/* properties dropdown menu for screen smaller than lg:breakpoint */}
          <div className='flex gap-2 items-center xl:hidden'>
            {/* properties list dropdown */}
            <div className='flex gap-3 items-center'>
              {/* label for properties dropdown */}

              <label className='text-slate-500 whitespace-nowrap'>
                Property List{' '}
                <TooltipIcon
                  id='property-list-tooltip'
                  tooltip='Switch between the primary and available associated property lists'
                  style={{ fontSize: '0.8rem', color: 'var(--dm-primary)' }}
                />
              </label>

              {/* properties dropdown */}
              <PropertiesDropdown />
            </div>
          </div>
        </div>

        {/* 
        RIGHT 
        */}
        <div className='flex items-center gap-3 flex-nowrap justify-end basis-1/3'>
          <div className='flex gap-5 items-center'>
            {activePropertyGroup !== 'primary' && (
              <>
                <div className='bg-slate-300 w-[0.1px] h-[70%]' />

                <div className='flex items-center gap-3 flex-nowrap'>
                  <label className='text-slate-500 whitespace-nowrap'>
                    Use Type{' '}
                    <i
                      className='pi pi-question-circle cursor-pointer text-primary-500 text-sm'
                      onClick={() => setAssociationInstructionsVisible(!associationInstructionsVisible)}
                    />
                  </label>

                  <Dropdown
                    value={useType}
                    options={useTypeOptions}
                    onChange={(e) => setPropertiesView({ useType: e.value })}
                    tooltip='Select "All items" to display values from all items in the list. Select "First item" to display values from a single item in the list.'
                    tooltipOptions={{ showDelay: 500, position: 'bottom' }}
                    placeholder='Select Use Type'
                  />
                </div>
              </>
            )}
            <label className='text-slate-500 whitespace-nowrap'>
              Formatters{' '}
              <i
                id='formatters-help-icon'
                className='pi pi-question-circle cursor-pointer text-primary-500 text-sm px-0'
                onClick={(e) => formattersInstructionsRef.current?.toggle(e)}
              />
            </label>
            <OverlayPanel ref={formattersInstructionsRef} pt={{}}>
              Formatters are used to customize the format of date, time, number,
              <br /> currency, and percent values. <br />
              <br />
              Example
              <br />
              <table className={styles.instructionsTable}>
                <tr>
                  <th>Scenario</th>
                  <th>Syntax</th>
                  <th>Output</th>
                </tr>
                <tr>
                  <td>Without Formatter</td>
                  <td>{'{{amount}}'}</td>
                  <td className='text-right'>2524.64</td>
                </tr>
                <tr>
                  <td>With Formatter</td>
                  <td>{'{{$number amount "$0,0.00"}}'}</td>
                  <td className='text-right'>$2,524.64</td>
                </tr>
              </table>
              <br />
              Check out the{' '}
              <a
                href='https://docs.documint.me/templates/data-and-variables/formatting'
                target='_blank'
                rel='noreferrer'
                className='text-primary-500 px-1'
              >
                Help Center
              </a>
              for more details.
            </OverlayPanel>
          </div>

          <InputSwitch
            checked={isFormatted || false}
            onChange={toggleIsFormatted}
            tooltipOptions={{ showDelay: 1000, position: 'bottom' }}
          />
        </div>
      </div>

      {/* 
      ABSOLUTES 
      */}

      {/* association instructions modal */}
      <AssociationInstructions
        visible={associationInstructionsVisible}
        hide={() => setAssociationInstructionsVisible(false)}
      />
    </>
  );
}
