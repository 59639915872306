import { InputSwitch } from 'primereact/inputswitch';
import { useEffect, useMemo, useRef } from 'react';
import { useObjectTypes } from '../../objectTypes';
import { useQuickCreate } from '../hooks';
import { Toast } from 'primereact/toast';
import { SHARED_CACHE_KEY } from '../../../views/settings';

export default function QuickCreateToggle({ forObjectType = false }: { forObjectType?: boolean }) {
  const errorToastRef = useRef<Toast>(null);

  const { quickCreateData, objectTypeQuickCreateData, toggleQuickCreate, updateMetaData } = useQuickCreate({
    fixedCacheKey: SHARED_CACHE_KEY,
  });

  // Picks the correct quick create data based on forObjectType
  const quickCreateEnabled = useMemo(() => {
    if (!forObjectType) return quickCreateData?.enabled;

    return objectTypeQuickCreateData?.enabled;
  }, [forObjectType, quickCreateData, objectTypeQuickCreateData]);

  // Selected object type for forObjectType = true
  const { selectedObjectType } = useObjectTypes();

  const tooltipMessage =
    forObjectType && selectedObjectType ? `quick-create for ${selectedObjectType.pluralLabel}` : 'quick-create';

  const handleToggle = () => {
    if ((forObjectType && !selectedObjectType) || !quickCreateData) return;
    toggleQuickCreate({
      objectTypeName: forObjectType ? selectedObjectType?.objectTypeName : undefined,
      data: quickCreateData,
    });
  };

  // Display error toast if error occurred while saving changes
  useEffect(() => {
    if (updateMetaData.error) {
      errorToastRef.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: updateMetaData.error.message || 'An error occurred while saving your changes.',
        life: 3000,
      });
    }
  }, [updateMetaData.error]);

  return (
    <>
      <InputSwitch
        checked={quickCreateEnabled ?? true}
        onChange={() => handleToggle()}
        tooltip={`${quickCreateData?.enabled ? 'Disable' : 'Enable'} ${tooltipMessage}`}
        data-pr-showdelay={500}
        data-pr-position='bottom'
      />
      <Toast ref={errorToastRef} position='bottom-center' />
    </>
  );
}
