import React, { useEffect } from 'react';

import { useCreateDocumentMutation } from '../../../features/documents';
import { useDocument } from '../../../features/documents/hooks/useDocument';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import { DocumentWorker, EmptyState, DocumentViewerSkeleton } from '../../../features/documents';

import DocumentErrorMessage from '../../../features/documents/components/DocumentErrorMessage';

export default function DocumentView() {
  const { paramsChanged, currentParams, documentUrl, setDocumentUrl, setPrevParams } = useDocument();

  const [createDocument, documentMeta] = useCreateDocumentMutation({ fixedCacheKey: 'document' });

  // Looks out for document param changes. If there are changes, it creates a new document, else it does nothing.
  useEffect(() => {
    if (!paramsChanged || !currentParams) return;
    createDocument(currentParams);
    setPrevParams(currentParams);
  }, [paramsChanged, currentParams, createDocument, setPrevParams]);

  // Set the document url when the document is created
  useEffect(() => {
    if (documentMeta.data) {
      setDocumentUrl(documentMeta.data.url);
    }
  }, [documentMeta, setDocumentUrl]);

  // Content to be displayed in the document viewer
  let content;
  if (documentMeta.isLoading) {
    content = (
      <div className='p-[10px] h-full w-full'>
        <DocumentViewerSkeleton />
      </div>
    );
  } else if (documentMeta.error) {
    content = <DocumentErrorMessage error={documentMeta.error} />;
  } else if (documentUrl && documentMeta.isSuccess) {
    content = <DocumentWorker documentUrl={documentUrl} />;
  } else if (!currentParams) {
    content = <EmptyState />;
  }

  return <div className='document-viewer h-full break-words overflow-hidden'>{content}</div>;
}
