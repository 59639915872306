import formatters from '../../utils/formatters';
import { Tooltip } from 'primereact/tooltip';
import InfoIcon from '../../../../components/TooltipIcon';
import { CustomProperty } from '../../propertiesApi';
import usePropertiesUi from '../../hooks/usePropertiesUi';

export function PropertyTableColumnBody({ property }: { property: CustomProperty }) {
  const {
    propertiesView: { isFormatted, useType, activePropertyGroup },
  } = usePropertiesUi();

  const { key, relatedObjectProperties, parentName, name, fieldType, type } = property || {};
  const iconKey = `${key}-1`;

  // This will notify us if parent properties are being used
  if (relatedObjectProperties?.length)
    throw new Error('Parent properties are not supported in this PropertiesTableColumnBody.tsx component');

  let propertyValue = '';
  if (activePropertyGroup === 'primary' || useType === 'loop') {
    propertyValue = name;
  } else {
    propertyValue = `${parentName}.0.${name}`;
  }

  const formatter = fieldType ? formatters[`${type}:${fieldType}`] : undefined;
  if (formatter && isFormatted) propertyValue = formatter(propertyValue);
  propertyValue = `{{${propertyValue}}}`;

  const tooltip =
    useType === 'loop' && !relatedObjectProperties?.length
      ? `Paste this inside element(s) looping over '${parentName}'`
      : 'Paste this property into your documint template';

  return (
    <>
      <div className='flex'>
        {propertyValue && (
          <div className='flex items-center'>
            <code className='bg-slate-100 border border-slate-200 py-0.5 px-2 rounded break-keep text-sm mx-0.5'>
              {propertyValue}
            </code>
            <InfoIcon id={iconKey} tooltip={tooltip} />
          </div>
        )}
      </div>

      <Tooltip target={`#${iconKey}`} className='z-1000' />
    </>
  );
}

export default PropertyTableColumnBody;
