import { apiSlice } from '../../api';
import { transformSingleResponse } from '../../api/utils';

// The quick create data for a particular object type
export interface ObjectTypeQuickCreateData {
  templateIds: string[];
  enabled: boolean;
  userDefined: boolean;
}

// Total quick create data
export interface QuickCreateData {
  enabled: boolean;
  [key: string]: ObjectTypeQuickCreateData | boolean;
}

export interface SettingsData {
  user: {
    userId: string;
    userEmail: string;
    apiKey: string;
  };
  quickCreateData: QuickCreateData | undefined;
}
export const settingsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSettings: builder.query<SettingsData, void>({
      query: () => `/settings`,
      providesTags: ['Settings'],
      transformResponse: transformSingleResponse<SettingsData>,
    }),
    updateSettings: builder.mutation<SettingsData, Partial<SettingsData>>({
      query: (data) => ({
        url: `/settings`,
        method: 'PUT',
        body: data,
      }),
      // Optimistic update
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        const putResult = dispatch(
          settingsApi.util.updateQueryData('getSettings', undefined, (draft) => {
            Object.assign(draft, data);
          }),
        );
        try {
          await queryFulfilled;
        } catch (error) {
          putResult.undo();
        }
      },
      invalidatesTags: ['Settings'],
      transformResponse: transformSingleResponse<SettingsData>,
    }),
  }),
});

export const { useGetSettingsQuery, useUpdateSettingsMutation, useLazyGetSettingsQuery } = settingsApi;
