import { RiDraggable } from 'react-icons/ri';
import { Direction, PickListItem } from './DndPickList';

export interface DndPickListItemProps {
  id: string;
  idField: string;
  displayField: string;
  item: PickListItem;
  direction: Direction;
  activeItemId: string | null;
  isOverlay: boolean;
  disabled?: boolean;
  onClick: (id: string, direction: Direction) => void;
  getIsSelected: (itemId: string) => boolean;
}

export default function DndPickListItem(props: DndPickListItemProps) {
  const {
    id,
    item,
    idField,
    displayField,
    direction,
    onClick,
    getIsSelected,
    activeItemId,
    isOverlay,
    disabled = false,
  } = props;

  const isActive = id === activeItemId;

  // isGhost is used to indicate that the item is being dragged and the
  // DragOverlay appears prominently while the item itself is ghosted to indicate its position.
  const isGhost = isActive && !isOverlay;

  const isSelected = getIsSelected(id) && !isActive;

  return (
    <div
      className={`z-[1000] bg-white flex flex-row justify-start items-center w-full h-[3.5rem] px-2 rounded-md border-[1px] border-slate-300 shadow-md
         ${disabled ? 'hover:shadow-md cursor-default' : 'hover:shadow-lg'} 
         ${isGhost && '!bg-gray-100'}
         ${isSelected && '!bg-slate-50'} 
         `}
      onClick={() => {
        if (!direction || disabled) {
          return;
        }
        onClick(item[idField], direction);
      }}
    >
      <RiDraggable className='text-xl text-slate-500 mr-2' />
      <p
        className={`w-full text-ellipsis text-nowrap whitespace-nowrap overflow-hidden ml-1 ${
          isGhost && 'text-slate-300'
        }`}
      >
        {item[displayField]}
      </p>
    </div>
  );
}
