import React, { useState } from 'react';

import { LoadError, SpecialZoomLevel, Viewer, Worker } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';

import DocumentErrorMessage from './DocumentErrorMessage';
import DocumentViewerSkeleton from './DocumentViewerSkeleton';
import DocumentViewerControls from './DocumentViewerControls';

import type { ErrorResponse } from '../../../api/types';

export interface DocumentWorkerProps {
  documentUrl: string;
}

function DocumentWorker({ documentUrl }: DocumentWorkerProps): JSX.Element {
  const [error, setError] = useState<ErrorResponse | null>(null);

  const toolbarInstance = toolbarPlugin();

  return (
    <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js'>
      <Viewer
        fileUrl={documentUrl}
        defaultScale={SpecialZoomLevel.PageFit}
        plugins={[toolbarInstance]}
        renderError={(error: LoadError) => {
          setError({ message: error.message || 'Error loading document', status: 500 });
          return <DocumentErrorMessage error={error} />;
        }}
        renderLoader={() => <DocumentViewerSkeleton />}
        pageLayout={{
          transformSize: ({ size }) => ({
            height: size.height + 10,
            width: size.width + 10,
          }),
        }}
      />
      {!error && <DocumentViewerControls toolbarInstance={toolbarInstance} />}
    </Worker>
  );
}

export const MemoizedDocumentWorker = React.memo(DocumentWorker, arePropsEqual);

function arePropsEqual(prevProps: DocumentWorkerProps, nextProps: DocumentWorkerProps): boolean {
  const propsEqual = prevProps.documentUrl === nextProps.documentUrl;
  return propsEqual;
}

export default MemoizedDocumentWorker;
