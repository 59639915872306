import { Dialog } from 'primereact/dialog';
import ObjectList from './ObjectList';
import { useObjectsUi } from '../../hooks';
import ObjectListHeader from './ObjectListHeader';

export default function ObjectListModal() {
  const { setObjectModalVisibility, objectModalVisibility } = useObjectsUi();

  const headerTemplate = <ObjectListHeader />;

  return (
    <Dialog
      onHide={() => setObjectModalVisibility(false)}
      header={headerTemplate}
      // We use our own button to close the modal, so we hide the default close button.
      pt={{ headerIcons: { className: '!hidden' } }}
      modal
      style={{ width: '80%', height: '90%' }}
      visible={objectModalVisibility}
      draggable={false}
    >
      <ObjectList />
    </Dialog>
  );
}
