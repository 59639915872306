import { useCallback, useMemo } from 'react';
import { DataView, DataViewPassThroughOptions } from 'primereact/dataview';
import { Tooltip } from 'primereact/tooltip';
import { useTemplates } from '../../hooks';
import { useNavigate } from 'react-router-dom';
import useTemplateUI from '../../hooks/useTemplatesUI';
import { useGetTemplatesPage } from '../../hooks/useGetTemplatesPage';
import { DEFAULT_TEMPLATE_LIMIT } from '../../templatesSlice';

// Components
import TemplateListEmptyState from './TemplateListEmptyState';
import TemplateListItem from './table/TemplateListItem';
import GridItem from './grid/TemplateListGridItem';
import { Paginator } from 'primereact/paginator';
import TemplateGridSkeleton from './grid/TemplateGridSkeletonLayout';
import TemplateListSkeleton from './table/TemplateListSkeletonLayout';

import type { Template } from '../../templatesApi';
import { useGetObjectsPage } from '../../../objects';

// See Paginator component for the shape of the page data.
type PageDataType = { first: number; rows: number; page: number; pageCount: number };

export function TemplateList() {
  const { templatesPageData, templatesPageMeta } = useGetTemplatesPage();
  const { setTemplateId } = useTemplates();
  const { setTemplatesModalVisibility, templatesLayout, templateParams, templatesSearchValue, setTemplateParams } =
    useTemplateUI();

  // Prefetch objects for dashboard
  useGetObjectsPage();

  const templates = useMemo(() => templatesPageData?.data, [templatesPageData]);
  const isFetching = useMemo(() => templatesPageMeta.isFetching, [templatesPageMeta]);

  // Template params does not have a first property, so we calculate it here.
  const first = useMemo(() => {
    const { page, limit } = templateParams;
    if (!page || !limit) return 0;
    return page * limit - 1;
  }, [templateParams]);

  const navigate = useNavigate();

  const ptOptions: DataViewPassThroughOptions = useMemo(
    () => ({
      grid: {
        className:
          (!templatesLayout || templatesLayout === 'grid') && templates?.length
            ? 'grid-cols-3 md:grid-cols-4 xl:grid-cols-5 gap-3 p-5'
            : '',
      },
      header: { style: { padding: '.5em 1em' } },
      emptyMessage: {
        className: 'p-10 text-center w-full items-center flex justify-center',
        style: { minHeight: 'calc(100vh - 60px)' },
      },
      content: { style: { background: 'transparent' } },
      loadingOverlay: { style: { backgroundColor: 'rgba(255,255,255,.7)', zIndex: 100 } },
    }),
    [templatesLayout, templates],
  );

  const handlePageDataChange = useCallback(
    (pageData: PageDataType) => {
      setTemplateParams({ page: pageData.page + 1, limit: pageData.rows });
    },
    [setTemplateParams],
  );

  const handleSelectTemplate = useCallback(
    (template: Template) => {
      setTemplateId(template.id);
      navigate('/dashboard');
      setTemplatesModalVisibility(false);
    },
    [setTemplateId, navigate, setTemplatesModalVisibility],
  );

  const handleReload = useCallback(() => {
    templatesPageMeta.refetch();
  }, [templatesPageMeta]);

  const listItem = useCallback(
    (template: Template) => <TemplateListItem template={template} onSelect={handleSelectTemplate} />,
    [handleSelectTemplate],
  );
  const gridItem = useCallback(
    (template: Template) => <GridItem template={template} onSelect={handleSelectTemplate} />,
    [handleSelectTemplate],
  );
  const itemTemplate = useMemo(
    () => (templatesLayout === 'grid' || !templatesLayout ? gridItem : listItem),
    [templatesLayout, gridItem, listItem],
  );

  return (
    <div className='flex flex-col gap-4 h-full w-full overflow-hidden'>
      <div className='flex-auto overflow-y-auto border bg-slate-50'>
        {isFetching ? (
          <>{templatesLayout === 'grid' ? <TemplateGridSkeleton /> : <TemplateListSkeleton />}</>
        ) : templates?.length ? (
          <div className='flex flex-col items-center min-h-[100%] justify-stretch'>
            <DataView
              value={isFetching ? [] : templates}
              itemTemplate={itemTemplate}
              header={false}
              layout={templatesLayout || 'grid'}
              pt={ptOptions}
              loading={isFetching}
              className='h-full'
              emptyMessage="Couldn't find any templates"
              style={{ minHeight: '100%', width: '100%', flexGrow: '1' }}
            />
            <Paginator
              onPageChange={(pageData: PageDataType) => {
                handlePageDataChange(pageData);
              }}
              rows={templateParams.limit}
              style={{ marginTop: '1rem' }}
              first={first}
              totalRecords={templatesPageData?.total}
              rowsPerPageOptions={[DEFAULT_TEMPLATE_LIMIT, 30, 40]}
            />
          </div>
        ) : (
          <div className='h-full flex justify-center items-center'>
            <TemplateListEmptyState onReload={handleReload} searching={Boolean(templatesSearchValue?.length)} />
          </div>
        )}
        <Tooltip target='.active-badge' />
      </div>
    </div>
  );
}

export default TemplateList;
