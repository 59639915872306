import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store/index';
import { setObjectTypeId } from '../objectTypes';

export type ActiveGroupType = 'primary' | string;
export type UseType = 'first' | 'loop';
export type DefinedBy = 'all' | 'hubspot' | 'user';

export type PropertiesUiSliceState = {
  ui: {
    view: {
      searchValue: string;
      useType: UseType;
      isFormatted: boolean;
      definedBy: DefinedBy;
      groupName: string | undefined;
      activePropertyGroup: ActiveGroupType; // Can be primary or the name of the association list group name
    };
    propertiesViewVisible: boolean; // For false properties page
  };
};

const initialState: PropertiesUiSliceState = {
  ui: {
    view: {
      searchValue: '',
      useType: 'first',
      isFormatted: false,
      definedBy: 'all',
      groupName: undefined,
      activePropertyGroup: 'primary',
    },
    propertiesViewVisible: false,
  },
};

export const propertiesUiSlice = createSlice({
  name: 'properties',
  initialState,
  reducers: {
    setPropertiesView(state, action: PayloadAction<Partial<PropertiesUiSliceState['ui']['view']>>) {
      state.ui.view = { ...state.ui.view, ...action.payload };
    },
    resetPropertiesView(state) {
      state.ui.view = initialState.ui.view;
    },
    setPropertiesViewVisible(state, action: PayloadAction<boolean>) {
      state.ui.propertiesViewVisible = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setObjectTypeId, (state) => {
      console.log('this should have happened');
      state.ui.view = { ...state.ui.view, activePropertyGroup: 'primary', groupName: undefined };
    });
  },
});

export const { setPropertiesView, resetPropertiesView, setPropertiesViewVisible } = propertiesUiSlice.actions;

export const selectPropertiesView = (state: RootState) => state.properties.ui.view;
export const selectPropertiesViewVisible = (state: RootState) => state.properties.ui.propertiesViewVisible;

export default propertiesUiSlice.reducer;
