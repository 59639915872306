import { useCallback } from 'react';

import { Paginator } from 'primereact/paginator';
import { useObjectTypes } from '../../../objectTypes';
import ObjectsTable from './table/ObjectTable';
import { useObjectsUi } from '../../hooks';
import { useGetObjectsPage } from '../../hooks/useGetObjectsPage';

// See Paginator component for the shape of the page data.
export type PageDataType = { first: number; rows: number; page: number; pageCount: number };

export default function ObjectList() {
  const { objectTypeId } = useObjectTypes();
  const { objectParams, setObjectParams } = useObjectsUi();
  const { objectsPageData } = useGetObjectsPage();

  // Called when user selects another page.
  const handlePageChange = useCallback(
    (pageData: PageDataType) => {
      if (!objectParams || !objectTypeId) return;
      setObjectParams({ after: pageData.first, limit: pageData.rows });
    },
    [objectParams, setObjectParams, objectTypeId],
  );

  return (
    <div className='flex flex-col gap-4 h-full'>
      {/* CONTENT */}
      <div className='flex-1 flex flex-col gap-5 border items-center justify-between h-full w-full bg-slate-50 overflow-auto'>
        <ObjectsTable />

        <Paginator
          first={Number(objectParams.after) || 0}
          rows={objectParams.limit}
          totalRecords={objectsPageData?.total || 0}
          onPageChange={(pageData: PageDataType) => handlePageChange(pageData)}
          rowsPerPageOptions={[25, 40, 50]}
        />
      </div>
    </div>
  );
}
