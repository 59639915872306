import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import config from '../../../config';
import { TemplateModal, TemplateSelectionControls, TemplateSettingsModal } from '../../../features/templates';
import { GenerateDocumentButton } from '../../../features/documents';
import { ObjectListModal, ObjectSelectionControls } from '../../../features/objects';
import { useObjectTypes } from '../../../features/objectTypes';
import { useGetPropertiesQuery } from '../../../features/properties';
import usePropertiesUi from '../../../features/properties/hooks/usePropertiesUi';

export default function SideBar() {
  const { objectTypeId } = useObjectTypes();
  const { setPropertiesViewVisible } = usePropertiesUi();

  // Prefetches properties for the selected object type
  useGetPropertiesQuery(objectTypeId || '', { skip: !objectTypeId });

  return (
    <>
      <div className='flex flex-col gap-16 pr-8 pl-1 pb-5 h-screen overflow-y-auto overflow-x-hidden justify-between items-center w-full bg-white'>
        <div className='flex flex-col gap-8 w-full'>
          {/* Documint logo */}
          <div className='flex w-full items-center justify-center py-4'>
            <Link to={config.documintURL} target='_blank'>
              <img src='images/logo.svg' alt='Documint logo' className='h-[32px] w-auto' />
            </Link>
          </div>

          <ObjectSelectionControls />

          {/* line separates object controls and template controls */}
          <hr className='border-neutral-200 border-1' />

          {/* Template Controls */}
          <TemplateSelectionControls />

          {/* refresh and create buttons */}
          <div className='flex gap-2 mt-2 items-center justify-between'>
            <GenerateDocumentButton
              className='w-20 p-2'
              icon='pi pi-refresh'
              iconPos='left'
              isPreview={true}
              outlined
            />
            <GenerateDocumentButton
              tooltip='Generate document and save as note'
              icon='pi pi-file-export'
              iconPos='right'
              label='Generate'
              className='w-full justify-around'
              isPreview={false}
            />
          </div>

          {/* original design with absolute generate button */}
          {/* <GenerateDocumentButton
            tooltip='Generate document and save as note'
            className='flex-auto absolute top-4 right-8 z-[1000]'
            isPreview={false}
            />
            
            <GenerateDocumentButton
            className='flex-auto w-full'
            label='Refresh Preview'
            icon='pi pi-refresh'
            iconPos='left'
            isPreview={true}
            outlined
            /> */}
        </div>

        {/* Footer */}
        <div className='flex flex-row gap-4 w-full items-center justify-center'>
          {/* properties button */}
          <Button
            onClick={() => setPropertiesViewVisible(true)}
            text
            className='grow-0 px-1 py-0 -ml-4'
            label='Properties'
            severity='secondary'
            icon={<span className='px-2'>&#123;...&#125;</span>}
          />

          {/* help center button */}
          <Link to={config.docsURL} target='_blank' className='grow-0'>
            <Button text icon='pi pi-book' label='Help' className='px-1 py-0' severity='secondary' />
          </Link>
        </div>
      </div>

      {/* modals */}
      <ObjectListModal />
      <TemplateModal />
      <TemplateSettingsModal />
    </>
  );
}
