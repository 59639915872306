import { RootState } from '../../store/index';

import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { templatesApi } from './templatesApi';

import type { Template, TemplatesQuery } from './templatesApi';
import type { ListResponse } from '../../api/types';
import { getSearchRegex } from './utils';

export type LayoutOption = 'grid' | 'list' | null;
export type ActiveOption = 'all' | 'active' | 'inactive';

const templatesAdapter = createEntityAdapter<Template>();

interface TemplatesSliceState extends ReturnType<typeof templatesAdapter.getInitialState> {
  data: {
    templateId: string | null;
  };
  ui: {
    modalVisible: boolean;
    settingsModalVisible: boolean;
    layout: LayoutOption;
    activeOption: ActiveOption;
    params: TemplatesQuery;
    searchValue: string | undefined;
  };
}

export const DEFAULT_TEMPLATE_LIMIT = 20;

const initialState: TemplatesSliceState = templatesAdapter.getInitialState({
  data: {
    templateId: null,
  },
  ui: {
    modalVisible: false,
    layout: 'grid',
    activeOption: 'all',
    params: {
      select: '',
      limit: DEFAULT_TEMPLATE_LIMIT,
      page: 1,
      filters: { name: '' },
      sort: '-updatedAt',
    },
    searchValue: undefined,
    settingsModalVisible: false,
  },
});

export const templatesSlice = createSlice({
  name: 'templates',
  initialState: initialState,
  reducers: {
    // template data actions
    setTemplateId(state, action: PayloadAction<string>) {
      state.data.templateId = action.payload;
    },

    // template ui actions
    setTemplateParams(state, action: PayloadAction<Partial<TemplatesQuery>>) {
      // Remove the name filter, it needs to be controlled by the search input
      delete action.payload.filters?.name;

      state.ui.params = {
        ...state.ui.params,
        ...action.payload,
        filters: {
          ...state.ui.params.filters,
          ...action.payload.filters,
        },
      };
    },
    resetTemplateParams(state) {
      state.ui.params = initialState.ui.params;
    },
    setTemplatesLayout(state, action: PayloadAction<LayoutOption>) {
      state.ui.layout = action.payload;
    },
    setTemplatesActiveOption(state, action: PayloadAction<ActiveOption>) {
      state.ui.activeOption = action.payload;
    },
    setTemplatesModalVisibility(state, action: PayloadAction<boolean>) {
      if (action.payload === false) {
        state.ui.params = initialState.ui.params;
      }
      state.ui.modalVisible = action.payload;
    },
    setTemplatesSettingsModalVisibility(state, action: PayloadAction<boolean>) {
      state.ui.settingsModalVisible = action.payload;
    },
    setTemplatesSearchValue(state, action: PayloadAction<string | undefined>) {
      state.ui.searchValue = action.payload || undefined;
      state.ui.params.filters = {
        ...state.ui.params.filters,
        name: action.payload ? getSearchRegex(action.payload) : '',
      };
      state.ui.params.page = 1;
    },
    clearTemplatesSearchValue(state) {
      state.ui.searchValue = undefined;
      state.ui.params.filters = { name: '' };
      state.ui.params.page = 1;
    },
  },
  extraReducers: (builder) => {
    // Add matchers to update template list entity adapter from API responses
    builder.addMatcher(
      templatesApi.endpoints.getTemplate.matchFulfilled,
      (state: TemplatesSliceState, action: PayloadAction<Template>) => {
        templatesAdapter.addOne(state, action.payload);
      },
    );
    builder.addMatcher(
      templatesApi.endpoints.listTemplates.matchFulfilled,
      (state: TemplatesSliceState, action: PayloadAction<ListResponse<Template>>) => {
        templatesAdapter.addMany(state, action.payload.data);
      },
    );
  },
});

export const {
  setTemplateId,
  setTemplateParams,
  resetTemplateParams,
  setTemplatesLayout,
  setTemplatesActiveOption,
  setTemplatesModalVisibility,
  setTemplatesSearchValue,
  setTemplatesSettingsModalVisibility,
  clearTemplatesSearchValue,
} = templatesSlice.actions;

export const selectTemplateId = (state: RootState) => state.templates.data.templateId;
export const selectTemplateParams = (state: RootState) => state.templates.ui.params;
export const selectTemplatesModalVisibility = (state: RootState) => state.templates.ui.modalVisible;
export const selectTemplatesLayout = (state: RootState) => state.templates.ui.layout;
export const selectTemplatesActiveOption = (state: RootState) => state.templates.ui.activeOption;
export const selectTemplatesSearchValue = (state: RootState) => state.templates.ui.searchValue;
export const selectTemplateSettingsModalVisibility = (state: RootState) => state.templates.ui.settingsModalVisible;

export const {
  selectAll: selectAllTemplates,
  selectById: selectTemplateById,
  selectIds: selectTemplateIds,
} = templatesAdapter.getSelectors((state: RootState) => state.templates);

export default templatesSlice.reducer;
