import { ToolbarPlugin } from '@react-pdf-viewer/toolbar';

export function DocumentViewerControls({ toolbarInstance }: { toolbarInstance: ToolbarPlugin }): JSX.Element {
  const { zoomPluginInstance, pageNavigationPluginInstance, getFilePluginInstance } = toolbarInstance;
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
  const { CurrentPageInput, NumberOfPages } = pageNavigationPluginInstance;
  const { Download } = getFilePluginInstance;

  const controlGroupClass = 'flex flex-col items-center justify-center gap-3 text-center';

  return (
    <div
      className='absolute right-3 flex justify-center items-center z-20'
      style={{ transform: 'scale(.75)', top: '50%', marginTop: '-170px' }}
    >
      <div className='flex flex-col items-stretch bg-white py-3 rounded-lg grow-0 border border-neutral-100 shadow-md'>
        <div className={controlGroupClass}>
          <Download />
        </div>
        <hr className='mx-2 border-neutral-200 my-3' />
        <div className={controlGroupClass}>
          <ZoomInButton />
          <ZoomPopover />
          <ZoomOutButton />
        </div>
        <hr className='mx-2 border-neutral-200 my-3' />
        <div className={controlGroupClass}>
          <span className='text-neutral-400'>Page</span>
          <CurrentPageInput />
          <span className='text-neutral-400'>
            of <NumberOfPages />
          </span>
        </div>
      </div>
    </div>
  );
}

export default DocumentViewerControls;
