import { Tooltip } from 'primereact/tooltip';
import { TooltipHTMLAttributes } from 'primereact/tooltip/tooltipoptions';
import { HTMLAttributes } from 'react';

export type QuestionIconProps = {
  id: string;
  tooltip: string | void;
  position?: TooltipHTMLAttributes['data-pr-position'];
  showDelay?: TooltipHTMLAttributes['data-pr-showdelay'];
  hideDelay?: TooltipHTMLAttributes['data-pr-hidedelay'];
} & Partial<Omit<TooltipHTMLAttributes, 'data-pr-position' | 'data-pr-showdelay' | 'data-pr-hidedelay'>> &
  Partial<Omit<HTMLAttributes<HTMLSpanElement>, 'className'>>;

export default function QuestionIcon(props: QuestionIconProps) {
  const { id, tooltip, position = 'bottom', showDelay = 500, hideDelay = 0, ...rest } = props;

  if (!tooltip) return null;

  const tooltipId = `tooltip-${id}`;

  return (
    <>
      <Tooltip target={`#${tooltipId}`} className='z-50' />
      <span
        id={tooltipId}
        className='pi pi-question-circle flex justify-center items-center p-1 text-xs'
        data-pr-tooltip={tooltip}
        data-pr-position={position}
        data-pr-showdelay={showDelay}
        data-pr-hidedelay={hideDelay}
        {...rest}
      />
    </>
  );
}
