import { Skeleton } from 'primereact/skeleton';
import { useCallback, useEffect, useRef, useState } from 'react';

const DocumentViewerSkeleton = () => {
  const skeletonContainerRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number>(647);
  const [width, setWidth] = useState<number>(500);

  const handleResize = useCallback(() => {
    if (skeletonContainerRef.current) {
      const height = skeletonContainerRef.current.clientHeight;
      setHeight(height);
      setWidth(height * 0.77);
    }
  }, [setHeight, setWidth]);

  // Set the initial dimensions
  useEffect(() => {
    handleResize();
  }, [handleResize]);

  // Update the dimensions on resize
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  const ROWS = 5;

  return (
    <div
      ref={skeletonContainerRef}
      className='flex flex-col h-full justify-start items-center relative overflow-y-auto w-full'
    >
      <div style={{ width, height }} className='flex flex-col bg-white items-center justify-center'>
        <div className='flex flex-col w-[85%] h-[85%] justify-between'>
          <div className='flex justify-between w-full h-[17%]'>
            <div className='flex flex-col w-2/5 h-full justify-start items-end gap-[20%]'>
              <Skeleton width='100%' height='18%' />
              <Skeleton width='50%' height='18%' />
            </div>

            <div className='flex flex-col w-2/5 h-full justify-between'>
              <Skeleton width='100%' height='65%' />
              <Skeleton width='100%' height='18%' />
            </div>
          </div>

          <Skeleton width='100%' height='3%' />

          <Skeleton width='100%' height='6%' />

          <div className='flex flex-col w-full h-[32%] justify-between'>
            {Array.from({ length: ROWS }).map((k, i) => (
              <div className='flex w-full h-[9%] justify-between items-center' key={i}>
                <Skeleton width='45%' height='100%' />
                <Skeleton width='12%' height='100%' />
                <Skeleton width='12%' height='100%' />
                <Skeleton width='12%' height='100%' />
              </div>
            ))}
          </div>

          <div className='flex w-full justify-start h-[3%]'>
            <Skeleton height='100%' width='40%' />
          </div>

          <Skeleton width='100%' height='6%' />

          <div className='flex w-full justify-center h-[8%]'>
            <Skeleton
              width='fit-content'
              height='100%'
              className='p-[3%] flex items-center justify-center bg-primary-500 border-2 border-primary-600'
            >
              <p className={`text-white ${height > 800 ? 'text-xl' : height > 600 ? 'text-md' : 'text-xs'} `}>
                Creating Document
              </p>
            </Skeleton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentViewerSkeleton;
