import { Dropdown } from 'primereact/dropdown';
import ControlledSearchButtonInput from '../../../../components/ControlledSearchButtonInput';
import { Button } from 'primereact/button';
import { SelectButton } from 'primereact/selectbutton';
import { useGetTemplatesPage } from '../../hooks/useGetTemplatesPage';
import useTemplateUI from '../../hooks/useTemplatesUI';
import { useGetObjectsPage } from '../../../objects';
import { useCallback, useEffect } from 'react';
import { useDebounce } from 'primereact/hooks';
import { ActiveOption } from '../../templatesSlice';

const activeOptions: { label: string; value: ActiveOption }[] = [
  { label: 'All', value: 'all' },
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
];

export default function TemplateListHeader() {
  const { templatesPageMeta } = useGetTemplatesPage();
  const {
    templatesLayout,
    templatesActiveOption,
    setTemplatesActiveOption,
    setTemplatesModalVisibility,
    setTemplatesLayout,
    setTemplatesSearchValue,
    setTemplateParams,
  } = useTemplateUI();

  // Prefetch objects for dashboard
  useGetObjectsPage();

  const isFetching = templatesPageMeta.isFetching;

  // Debounce search value
  const [instantValue, debouncedSearchValue, debouncedSetSearchValue] = useDebounce<string | undefined>(undefined, 500);

  // Update template params when search value changes
  useEffect(() => {
    setTemplatesSearchValue(debouncedSearchValue || '');
  }, [debouncedSearchValue, setTemplateParams, setTemplatesSearchValue]);

  const handleActiveOptionChange = useCallback(
    (activeOption: ActiveOption) => {
      const params: any = { page: 1 };

      if (activeOption === 'active') {
        params.filters = { isActive: 'true' };
      } else if (activeOption === 'inactive') {
        params.filters = { isActive: 'false' };
      } else {
        params.filters = { isActive: undefined };
      }

      setTemplateParams(params);
      setTemplatesActiveOption(activeOption);
    },
    [setTemplatesActiveOption, setTemplateParams],
  );

  const handleReload = useCallback(() => {
    templatesPageMeta.refetch();
  }, [templatesPageMeta]);

  return (
    <div className='flex-initial grid grid-cols-3 py-2 px-1'>
      {/* LEFT */}
      <div className='flex justify-start items-center gap-3 '>
        <Dropdown
          options={activeOptions}
          value={templatesActiveOption}
          onChange={(e) => {
            handleActiveOptionChange(e.value);
          }}
          className='p-inputtext-sm'
        />
        <ControlledSearchButtonInput
          value={instantValue || ''}
          handleValueChange={(e) => {
            debouncedSetSearchValue(e);
          }}
        />
      </div>

      {/* CENTER */}
      <div className='flex justify-center items-center'>
        <h4 className='text-2xl font-medium text-slate-600'>Select a Template</h4>
      </div>

      {/* RIGHT */}
      <div className='flex items-center justify-end gap-3'>
        {/* template layout options */}
        <SelectButton
          value={templatesLayout}
          optionLabel='icon'
          pt={{ button: { style: { padding: '0.3rem 1rem 0.3rem' } } }}
          onChange={(e) => setTemplatesLayout(e.value)}
          options={[
            { icon: <i className='pi pi-bars'></i>, value: 'list' },
            { icon: <i className='pi pi-th-large'></i>, value: 'grid' },
          ]}
        />

        {/* refresh button */}
        <Button
          size='small'
          onClick={handleReload}
          disabled={isFetching}
          icon='pi pi-refresh'
          outlined
          severity='secondary'
        />

        {/* close button */}
        <Button
          size='small'
          onClick={() => setTemplatesModalVisibility(false)}
          icon='pi pi-times'
          outlined
          severity='secondary'
        />
      </div>
    </div>
  );
}
