import { HiCog } from 'react-icons/hi';
import { useTemplates } from '../../hooks';
import useTemplateUI from '../../hooks/useTemplatesUI';
import { useGetTemplatesPage } from '../../hooks/useGetTemplatesPage';
import SelectionControlsSkeleton from '../../../../components/SelectionControlsSkeleton';
import { Message } from 'primereact/message';
import { Badge } from 'primereact/badge';
import TemplateNote from '../TemplateNote';

export default function TemplateSelectionControls() {
  const { selectedTemplate: template } = useTemplates();
  const { setTemplateSettingsModalVisibility, setTemplatesModalVisibility } = useTemplateUI();

  const { templatesPageMeta } = useGetTemplatesPage();

  return (
    <>
      {templatesPageMeta?.isError ? (
        <Message severity='error' text={`Error loading templates`} />
      ) : templatesPageMeta?.isLoading ? (
        <SelectionControlsSkeleton />
      ) : (
        <div className='flex flex-col gap-2'>
          {/* template label with settings modal button */}
          <div className='flex gap-1 items-center'>
            <p className='text-sm text-slate-500'>Template</p>
            <HiCog
              className='cursor-pointer text-slate-500 h-full text-md'
              onClick={() => setTemplateSettingsModalVisibility(true)}
            />
          </div>

          {/* selected template label */}
          <div className='flex gap-5 items-center'>
            <div className='flex gap-2 items-center'>
              {template && (
                <Badge
                  id={`active-badge-${template?.id}`}
                  style={{
                    width: '0.6rem',
                    height: '0.6rem',
                    background: template?.isActive ? 'rgb(0, 155, 255)' : 'lightgray',
                  }}
                  // severity={isActive ? undefined : 'secondary'}
                  data-pr-tooltip={template.isActive ? 'Active' : 'Inactive'}
                  data-pr-position='right'
                  data-pr-showdelay={300}
                />
              )}
              <p
                className={`text-md max-w-[180px] overflow-hidden whitespace-nowrap overflow-ellipsis ${
                  !template && 'text-slate-400'
                }`}
              >
                {template ? template.name : 'None selected'}
              </p>
            </div>

            {/* select template button */}
            <p
              className='text-primary-500 text-sm hover:underline cursor-pointer'
              onClick={() => setTemplatesModalVisibility(true)}
            >
              {template ? 'change' : 'select'}
            </p>
          </div>

          {/* template note */}
          <div className='w-full mt-3'>
            {!template ? (
              <Message severity='warn' className='text-xs mt-2' text='Select a template to continue' />
            ) : (
              <TemplateNote />
            )}
          </div>
        </div>
      )}
    </>
  );
}
