import { useEffect, useRef, useState } from 'react';
import { QuickCreateToggle, TemplatePicker, useGetSettingsQuery, useQuickCreate } from '../../features/settings';
import { BlockUI } from 'primereact/blockui';
import { useObjectTypes } from '../../features/objectTypes';

export const SHARED_CACHE_KEY = 'quick-create';

// Currently settings only contains quick create data
export default function Settings() {
  const [saved, setSaved] = useState<boolean>(false);

  const gotInitialSettingsData = useRef<boolean>(false);
  const settingsResponse = useGetSettingsQuery();

  const { setObjectTypeId } = useObjectTypes();

  const { quickCreateData, setQuickCreate, updateMetaData } = useQuickCreate({ fixedCacheKey: SHARED_CACHE_KEY });

  // Initialize quick create data in store and sets first object type
  useEffect(() => {
    if (!gotInitialSettingsData.current) {
      const initialData = settingsResponse.data?.quickCreateData;
      if (!initialData) return;

      setQuickCreate(initialData);

      // Sets the first object type. This is necessary as quick create is launched from settings, so no object type comes in from the URL
      setObjectTypeId('CONTACT');

      gotInitialSettingsData.current = true;
    }
  }, [settingsResponse.data, setQuickCreate, setObjectTypeId]);

  // Set saved creates a temporary notification when the quick create data is saved
  useEffect(() => {
    if (updateMetaData.isSuccess) {
      setSaved(true);
      setTimeout(() => {
        setSaved(false);
      }, 2000);
    }
  }, [updateMetaData]);

  return (
    <>
      <div
        className='w-screen h-screen flex flex-col justify-center items-center py-8 px-7 overflow-y-auto'
        style={{ backgroundColor: '#f4f7ff' }}
      >
        <div className='bg-white mt-24 h-[100rem] w-full flex flex-col items-start rounded-lg p-10 gap-4'>
          <div className='flex items-center justify-between w-full'>
            {/* header for quick create and enabled toggle */}
            <div className='flex items-center gap-6'>
              <h1 className='text-2xl'>Quick-Create Documents</h1>

              <QuickCreateToggle />
            </div>

            {/* saved notification */}
            <div className='flex h-full items-center gap-3 text-slate-500 mr-3'>
              <p className='text-lg text-slate-500'>{updateMetaData.isLoading ? 'Saving' : saved ? 'Saved' : ''}</p>
              <i
                className={`pi ${
                  updateMetaData.isLoading ? 'pi-spinner animate-spin' : saved ? 'pi-check' : ''
                } p-0 w-fit h-fit m-0`}
              />
            </div>
          </div>

          {/* Block UI shows and hides quick create when enabled*/}
          <BlockUI blocked={!quickCreateData?.enabled} className='!bg-transparent'>
            <div
              className='w-full'
              style={!quickCreateData?.enabled ? { opacity: 0.2, filter: 'grayscale(100%)' } : undefined}
            >
              {/* subtitle */}
              <p className='text-md mb-3 text-lg'>Quickly generate documents without launching the Documint app.</p>

              {/* template picker controls */}
              <TemplatePicker />
            </div>
          </BlockUI>
        </div>
      </div>
    </>
  );
}
