import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { FiColumns } from 'react-icons/fi';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import DataTableSkeleton from './DataViewSkeleton';
import ColumnSelectMenu from './ColumnSelectMenu';
import HeaderWithControls from './HeaderWithControls';

import type { ReactNode, SyntheticEvent } from 'react';
import { ExpandedObjectInterface } from '../../../objectsApi';
import { DisplayPropertiesType, PropertyTypeOptions, useObjectTypes } from '../../../../objectTypes';
import { useGetObjectsPage, useObjectsUi } from '../../../hooks';
import useObjects from '../../../hooks/useObjects';
import { findObjectTitle } from '../../../utils';

export interface ColumnProps {
  label: string;
  field: string;
  type: PropertyTypeOptions;
  header: () => ReactNode;
  body: (object: ExpandedObjectInterface) => ReactNode;
}

export default function ObjectsTable() {
  // All column options
  const [columns, setColumns] = useState<ColumnProps[]>([]);
  // Selected and visible columns
  const [visibleColumns, setVisibleColumns] = useState<ColumnProps[]>([]);
  const columnSelectMenuRef = useRef<OverlayPanel>(null);

  const { selectedObjectType } = useObjectTypes();
  const { setObjectModalVisibility, objectParams } = useObjectsUi();
  const { setObjectId } = useObjects();
  const { objectsPageData, objectsPageMeta } = useGetObjectsPage();

  const isFetching = useMemo(() => objectsPageMeta.isFetching, [objectsPageMeta.isFetching]);
  const searchValue = useMemo(() => objectParams?.searchValue, [objectParams]);

  // Wraps the text in a paragraph tag and handles empty text
  const RenderText = (text: string, type: PropertyTypeOptions): JSX.Element => (
    <p>{!text.length ? '---' : type === 'datetime' ? new Date(text).toLocaleString() : text}</p>
  );
  // Wraps the header with controls
  const RenderHeader = (title: string, field: string, searchable: boolean, sortable: boolean) => (
    <HeaderWithControls title={title} field={field} searchable={searchable} sortable={sortable} />
  );

  // Assigns the columns based on the object type id and the display properties
  const assignColumns = useCallback(
    (displayProperties: DisplayPropertiesType[]) => {
      setColumns(
        displayProperties?.map((prop) => ({
          label: prop.label,
          field: prop.name,
          type: prop.type,
          header: () => RenderHeader(prop.label, prop.name, prop.type === 'string', prop.type !== 'bool'),
          body: (object: ExpandedObjectInterface) => RenderText(object.properties[prop.name] || '', prop.type),
        })),
      );
    },
    [setColumns],
  );

  // Assigns the default columns based on the object type id and the display properties
  const assignDefaultColumns = useCallback((displayProperties: DisplayPropertiesType[]) => {
    const defaultColumns = displayProperties?.filter((prop) => prop.default);

    setVisibleColumns(
      defaultColumns.map((prop) => ({
        label: prop.label,
        field: prop.name,
        type: prop.type,
        header: () => RenderHeader(prop.label, prop.name, prop.type === 'string', prop.type !== 'bool'),
        body: (object: ExpandedObjectInterface) => RenderText(object.properties[prop.name] || '', prop.type),
      })),
    );
  }, []);

  // Assigns the render, columns, and default columns based on the object type id
  useEffect(() => {
    assignColumns(selectedObjectType?.properties ?? []);
    assignDefaultColumns(selectedObjectType?.properties ?? []);
  }, [selectedObjectType, assignDefaultColumns, assignColumns]);

  const columnPercentage = useMemo(() => 60 / visibleColumns.length, [visibleColumns]);

  const handleColumnSelectMenuSave = (values: any[]) => {
    setVisibleColumns(values);
    columnSelectMenuRef?.current?.hide();
  };
  const handleColumnSelectMenuCancel = () => {
    columnSelectMenuRef?.current?.hide?.();
  };

  // does not navigate anywhere as objects is only used as modal on the dashboard page for now.
  const handleSelectObject = useCallback(
    (object: ExpandedObjectInterface) => {
      setObjectModalVisibility(false);
      setObjectId(object.id);
    },
    [setObjectId, setObjectModalVisibility],
  );

  return (
    <>
      {isFetching ? (
        <DataTableSkeleton />
      ) : (
        <div className='w-full'>
          <DataTable
            value={objectsPageData?.data || []}
            className='p-datatable-sm'
            // There is a skeleton loader for this component. See DataTableSkeleton.tsx
            loading={false}
            // These selection props mimic the behavior of selectable items. Can be swapped to multi if needed.
            selection={null}
            selectionMode={'single'}
            emptyMessage={
              objectsPageMeta.isError
                ? 'Error getting objects for selected object type. Try refreshing the page.'
                : searchValue?.length
                ? 'No search results'
                : `No objects for selected object type`
            }
            onSelectionChange={(e) => handleSelectObject(e.value as ExpandedObjectInterface)}
          >
            <Column
              style={{ width: '30%' }}
              header={() => RenderHeader('Title', 'title', true, true)}
              // Contact is the only type of object that needs a special render.
              body={(object) => RenderText(findObjectTitle(object), 'string')}
            />
            {visibleColumns.map((col: ColumnProps) => (
              <Column key={col.label} header={col.header} body={col.body} style={{ width: `${columnPercentage}%` }} />
            ))}
            <Column
              style={{ width: '95px' }}
              header={
                <>
                  <Button
                    text
                    onClick={(e: SyntheticEvent) => columnSelectMenuRef?.current?.toggle(e)}
                    icon={<FiColumns className='w-6 h-6' />}
                  />
                  <div className='hidden' id='column-select-anchor' />
                  {/* Column select menu 
                  replaces MultiSelect because the column select can only be updated on save otherwise
                  it shuts every time the table is updated */}
                  <ColumnSelectMenu
                    visibleColumns={visibleColumns}
                    columns={columns}
                    onSave={handleColumnSelectMenuSave}
                    onCancel={handleColumnSelectMenuCancel}
                    ref={columnSelectMenuRef}
                  />
                </>
              }
              body={(object) => <Button size='small' label='Select' onClick={() => handleSelectObject(object)} />}
            />
          </DataTable>
        </div>
      )}
    </>
  );
}
