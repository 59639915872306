import { useEffect, useRef, useState } from 'react';
import { useQuickCreate } from '../hooks';
import { ObjectTypeDropdown, useObjectTypes } from '../../objectTypes';
import { Template, useLazyListTemplatesQuery, useListTemplatesQuery, useTemplates } from '../../templates';

//Components
import DndPickList from '../../../components/DndPickList';
import { Toast } from 'primereact/toast';
import QuestionIcon from '../../../components/QuestionIcon';
import { InputSwitch } from 'primereact/inputswitch';

import { SHARED_CACHE_KEY } from '../../../views/settings';

import type { DndPickListChangeEvent } from '../../../components/DndPickList/DndPickList';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
import SelectedTemplatesList from './QuickCreateTemplateList';

export default function TemplatePicker() {
  const errorToast = useRef<Toast>(null);

  // Get all templates
  const templatesResponse = useListTemplatesQuery({ select: 'name', sort: '-updatedAt', limit: 100 });
  // Used to get missing templates. See the memo that sets items
  const [listTemplates] = useLazyListTemplatesQuery();
  // Access all templates entity adapter. Used to collect all templates even if they are not in the initial request
  const { allTemplates } = useTemplates();

  const { selectedObjectType } = useObjectTypes();

  // Get cached meta data
  const { quickCreateData, objectTypeQuickCreateData, toggleUserDefined, setTemplateIds } = useQuickCreate({
    fixedCacheKey: SHARED_CACHE_KEY,
    updateDelay: 2000,
  });

  // This state is used to manage the selected and available templates in the DndPickList
  const [items, setItems] = useState<{ target: Template[]; source: Template[] }>({
    target: [],
    source: [],
  });

  // Assigns items. Available templates need to exclude the selected templates, and our initial
  // templates request may not contain all the necessary templates, so we need to make a specific request if
  // any templates are missing.
  useEffect(() => {
    const templates = allTemplates || [];

    // If this object type does not contain any quick create data, we just set available templates to all templates
    if (!objectTypeQuickCreateData) {
      setItems({ target: [], source: templates });
      return;
    }

    // This bit checks if templates is missing any templates that are in objectTypeQuickCreateData.templateIds
    const missingTemplates: string[] = [];
    objectTypeQuickCreateData.templateIds.forEach((templateId) => {
      const template = templates.find((template) => template.id === templateId);
      if (!template) missingTemplates.push(templateId);
    });

    // Make a request for the missing templates and wait for the next cycle to set items
    if (missingTemplates.length) {
      listTemplates({ filters: { _id: String(missingTemplates.map((templateId) => [templateId])) } });
      setItems({ target: [], source: [...templates] });
      return;
    }

    setItems({
      source: templates.filter((template) => !objectTypeQuickCreateData.templateIds?.includes(template.id)),
      target: objectTypeQuickCreateData.templateIds.map(
        (templateId) => templates.find((template) => template.id === templateId)!,
      ),
    });
  }, [setItems, objectTypeQuickCreateData, listTemplates, allTemplates]);

  const handleOnChange = ({ items }: DndPickListChangeEvent) => {
    let shouldUpdateTemplates = false;

    // @ts-ignore
    setItems((prev) => {
      // We check here if any changes were made to the selected templates. If not it means the order of available
      // templates was changed and nothing else. In which case we don't need to update state.

      // First we check if the length of the selected templates has changed. If it has, we update the template ids
      // If not, we still check if the ids are the same and in the same order. If they are not, we update the template ids
      if (
        prev.target.length !== items.target.length ||
        prev.target.some((template, index) => template.id !== items.target[index].id)
      ) {
        shouldUpdateTemplates = true;
      }

      return items;
    });

    if (!shouldUpdateTemplates || !selectedObjectType || !quickCreateData) return;

    setTemplateIds({
      objectTypeName: selectedObjectType.objectTypeName,
      templateIds: items.target.map((template) => template.id),
      data: quickCreateData,
    });
  };

  const confirmAutoSave = (event: any) => {
    confirmPopup({
      target: event.target,
      message: 'Auto selected templates will overwrite your current selection. Are you sure you want to continue?',
      icon: 'pi pi-exclamation-triangle',
      acceptClassName: 'p-button-danger',
      className: 'w-[20rem] shadow-lg bg-gray-50',
      accept: () => {
        if (!selectedObjectType || !quickCreateData) return;
        toggleUserDefined({ objectTypeName: selectedObjectType.objectTypeName, data: quickCreateData });
      },
    });
  };

  return (
    <>
      <div className='flex flex-col w-full h-full items-center gap-5 mt-6 '>
        {/* Object type quick create data controls */}
        <div className='flex items-center w-full gap-8'>
          {/* object type dropdown */}
          <div className='flex gap-4 items-center px-5'>
            <div className='flex gap-2 items-center'>
              <h3>Object Type</h3>
              <QuestionIcon
                id='object-type-quick-create'
                tooltip='Selected templates apply to this object types'
                style={{ padding: 5 }}
              />
            </div>

            <ObjectTypeDropdown includeCustom={false} />
          </div>

          {/* Auto save controls */}
          <div className='flex gap-4 items-center'>
            <div className='flex gap-2 items-center'>
              <h3>Auto Select</h3>
              <QuestionIcon
                id={'auto-select-quick-create'}
                tooltip='Automatically selected recently used templates. Will overwrite any selected templates.'
                style={{ padding: 5 }}
              />
            </div>

            <InputSwitch
              checked={objectTypeQuickCreateData ? !objectTypeQuickCreateData.userDefined : false}
              onClick={(event) => {
                if (!selectedObjectType || !quickCreateData) return;
                if (
                  objectTypeQuickCreateData &&
                  objectTypeQuickCreateData?.userDefined === true &&
                  items.target.length
                ) {
                  confirmAutoSave(event);
                  return;
                }
                toggleUserDefined({ objectTypeName: selectedObjectType.objectTypeName, data: quickCreateData });
              }}
            />
          </div>
        </div>

        {!objectTypeQuickCreateData?.userDefined ? (
          <SelectedTemplatesList templates={items.target} />
        ) : (
          <DndPickList
            displayField='name'
            idField='id'
            items={items}
            onChange={handleOnChange}
            isLoading={templatesResponse.isFetching}
            targetLimit={5}
            targetHeader={
              objectTypeQuickCreateData && !objectTypeQuickCreateData?.userDefined ? 'Auto Selected' : undefined
            }
            targetDisabled={objectTypeQuickCreateData ? !objectTypeQuickCreateData.userDefined : false}
          />
        )}
      </div>

      {/* absolutes */}
      {/* error toast */}
      <Toast ref={errorToast} position='bottom-center' />

      {/* confirm */}
      <ConfirmPopup />
    </>
  );
}
