import { RiDragDropLine } from 'react-icons/ri';

export default function DropHereItem({ first }: { first: boolean }) {
  return (
    <div className={`w-full h-[3.5rem] bg-white rounded-md p-2 ${first ? 'opacity-95' : 'opacity-70'}`}>
      <div
        className={`flex items-center justify-center gap-3 h-full w-full border-2 border-dashed rounded-md ${
          first ? ' border-slate-400 text-slate-400' : 'border-slate-300 text-slate-50'
        }`}
      >
        <RiDragDropLine className='text-xl' />
        {/* <TbDragDrop2 className='text-slate-400 text-2xl' /> */}
        <p className='text-md text-center'>Drop here</p>
      </div>
    </div>
  );
}
