import { useDroppable } from '@dnd-kit/core';

export interface DroppableProps {
  id: string;
  children: React.ReactNode;
}

export default function Droppable({ children, id }: DroppableProps) {
  const useDropProps = useDroppable({ id });

  if (!useDropProps) throw new Error('Droppable must be used within a DndContext');

  const { setNodeRef } = useDropProps;

  return (
    <div ref={setNodeRef} className='w-full h-full'>
      {children}
    </div>
  );
}
