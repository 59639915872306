import React from 'react';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';

export default function LottieLoader({ scale }: { width?: number | string; height?: number | string; scale?: number }) {
  const style: React.CSSProperties = {};

  if (scale) {
    style.transform = `scale(${scale})`;
  }

  return (
    <DotLottieReact
      src='https://lottie.host/54adcb13-8b9d-4717-ad1b-8c116ac15b6f/gs47VglS6M.lottie'
      loop
      autoplay
      style={style}
    />
  );
}
