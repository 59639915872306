import { Message } from 'primereact/message';
import { Link } from 'react-router-dom';
import { useTemplates } from '../hooks';
import config from '../../../config';

export default function TemplateNote() {
  const { selectedTemplate } = useTemplates();

  return (
    <>
      {selectedTemplate && (
        <Message
          text={
            !selectedTemplate.isActive ? (
              <span className='text-sm'>
                Documents created from{' '}
                <Link to={config.inactiveURL} className='underline' target='_blank'>
                  inactive
                </Link>{' '}
                templates are{' '}
                <Link to={config.inactiveURL} className='underline' target='_blank'>
                  watermarked
                </Link>
                .
              </span>
            ) : (
              <span className='text-sm'>
                This template is {''}
                <Link to={config.inactiveURL} className='underline' target='_blank'>
                  active.
                </Link>{' '}
                Deactivate your template when testing.
              </span>
            )
          }
          className='w-full text-sm bg-neutral-100'
          severity='contrast'
          icon='pi pi-info-circle'
        />
      )}
    </>
  );
}
