import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Direction, Items, PickListItem } from './DndPickList';
import Droppable from '../Droppable';
import { Sortable } from '../Sortable';
import DndPickListItem from './DndPickListItem';
import DropHereItem from './DropHereItem';
import React from 'react';

export interface DndPickListSublistProps {
  id: string;
  idField: string;
  displayField: string;
  items: PickListItem[];
  selection: Items;
  targetLimit?: number;
  disabled?: boolean;
  direction: Direction;
  activeItemId: string | null;
  header?: string | React.ReactNode;
  isLoading: boolean;
  onClick: (id: string, direction: Direction) => void;
  getIsSelected: (itemId: string) => boolean;
}

export default function DndPickListSublist(props: DndPickListSublistProps) {
  const {
    items,
    targetLimit,
    activeItemId,
    idField,
    id,
    direction,
    getIsSelected,
    selection,
    displayField,
    onClick,
    isLoading,
    header,
    disabled = false,
  } = props;

  // When the user has selected more items than he can move to the target list, the header count will be red.
  const attemptingToExceedLimit =
    direction === 'target' && targetLimit && selection.source.length + items.length > targetLimit;

  // Source lists display a total number and the target list displays a count of selected items.
  let headerCount: string = '';
  if (direction === 'target' && targetLimit) {
    headerCount = `${items.length}/${targetLimit}`;
  } else {
    headerCount = `Total: ${items.length}`;
  }

  return (
    <div
      className={`w-[22rem] h-[26.5rem] rounded-md border-[0.5px] border-slate-300 ${
        disabled ? 'bg-gray-200 opacity-60' : 'bg-gray-50'
      }`}
    >
      {/* header */}
      <div className='flex flex-row justify-between items-center w-full p-4 h-[3.5rem] bg-white border-b-[1px] border-b-slate-300 rounded-t-md'>
        {header && typeof header !== 'string' ? (
          header
        ) : (
          <h3 className='text-md font-semibold'>
            {header ? header : direction === 'source' ? 'Available' : 'Selected'}
          </h3>
        )}
        {/* show count of selected templates */}
        <p className={attemptingToExceedLimit ? 'text-rose-500 font-semibold' : ''}>{headerCount}</p>
      </div>

      {/* Nested divs keeps scrollbar off of list items and prevents
      contents from butting against the header and footer when scrolling. */}
      <div className='flex flex-col h-[23rem] w-full items-center justify-center'>
        {/* loading state */}
        {isLoading ? (
          <div className='w-full h-full justify-center items-center flex flex-col'>
            <i className='pi pi-spin pi-spinner text-4xl text-slate-300' />
          </div>
        ) : (
          // list of templates
          <div className='w-full h-[21rem] px-5 overflow-x-hidden overflow-y-auto'>
            <SortableContext items={items.map((item) => item[idField])} strategy={verticalListSortingStrategy}>
              {/* Sortable context requires a droppable container because it allows us to drag back to an empty list. 
              See https://docs.dndkit.com/presets/sortable#multiple-containers*/}
              <Droppable id={id}>
                <div className='flex flex-col w-full h-full items-center justify-start gap-3'>
                  {items.map((item, index) => (
                    // Sortable also includes draggable.
                    <Sortable key={index} id={item[idField]} data={item} disabled={disabled}>
                      <DndPickListItem
                        key={index}
                        id={item[idField]}
                        direction={direction}
                        displayField={displayField}
                        idField={idField}
                        item={item}
                        onClick={onClick}
                        getIsSelected={getIsSelected}
                        activeItemId={activeItemId}
                        isOverlay={false}
                        disabled={disabled}
                      />
                    </Sortable>
                  ))}
                  {/* Map drop here placeholders to the target limit. */}
                  {targetLimit &&
                    direction === 'target' &&
                    items.length < targetLimit &&
                    Array.from({ length: targetLimit - items.length }).map((_, index) => (
                      <DropHereItem key={index} first={false} />
                    ))}
                </div>
              </Droppable>
            </SortableContext>
          </div>
        )}
      </div>
    </div>
  );
}
