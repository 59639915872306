import { Button } from 'primereact/button';
import { DndPickListChangeEvent, DndPickListTransferEvent, Items } from './DndPickList';

export interface DndPickListTransferControlsProps {
  items: Items;
  selection: Items;
  onChange: (args: DndPickListChangeEvent) => void;
  clearSelection: () => void;
  targetLimit?: number;
  disabled?: boolean;
  targetDisabled?: boolean;
  sourceDisabled?: boolean;

  // Optional callbacks. These can be implemented if specific actions are required when moving items.
  onMoveToSource?: (args: DndPickListTransferEvent) => void;
  onMoveAllToSource?: (args: DndPickListTransferEvent) => void;
  onMoveToTarget?: (args: DndPickListTransferEvent) => void;
  onMoveAllToTarget?: (args: DndPickListTransferEvent) => void;
}

const SEVERITY = undefined;

export default function DndPickListTransferControls(props: DndPickListTransferControlsProps) {
  const {
    items,
    selection,
    clearSelection,
    onChange,
    targetLimit,
    disabled = false,
    targetDisabled = false,
    sourceDisabled = false,
  } = props;

  const hitTargetLimit = targetLimit ? items.target.length >= targetLimit : false;

  const transferDisabled = disabled || targetDisabled || sourceDisabled;

  // Move to target is disabled when there are no source items selected, the target limit is hit,
  // or the target limit is set and the sum of the target items and the selected source items exceeds the target limit.
  const moveToTargetDisabled =
    transferDisabled ||
    selection.source.length === 0 ||
    hitTargetLimit ||
    (targetLimit ? items.target.length + selection.source.length > targetLimit : false);

  // Move all to target is disabled when there are no source items or the target limit is hit.
  const moveAllToTargetDisabled = transferDisabled || items.source.length === 0 || hitTargetLimit;

  const MoveToSourceDisabled = transferDisabled || selection.target.length === 0;
  const MoveAllToSourceDisabled = transferDisabled || items.target.length === 0;

  const handleMoveToSource = (event: any) => {
    clearSelection();

    onChange({
      originalEvent: event,
      items: {
        target: items.target.filter((item) => !selection.target.includes(item)),
        source: [...items.source, ...selection.target],
      },
    });
  };

  const handleMoveAllToSource = (event: any) => {
    clearSelection();

    onChange({
      originalEvent: event,
      items: {
        target: [],
        source: [...items.source, ...items.target],
      },
    });
  };

  const handleMoveToTarget = (event: any) => {
    clearSelection();

    onChange({
      originalEvent: event,
      items: {
        source: items.source.filter((item) => !selection.source.includes(item)),
        target: [...items.target, ...selection.source],
      },
    });
  };

  const handleMoveAllToTarget = (event: any) => {
    clearSelection();

    // Find the number of items that can be moved to the target
    const movedSourceItems = targetLimit
      ? [...items.source.slice(0, targetLimit - items.target.length)]
      : [...items.source];
    // Find the remaining source items depending on how many have been moved to the target
    const remainingSourceItems = targetLimit ? [...items.source.slice(targetLimit - items.target.length)] : [];

    onChange({
      originalEvent: event,
      items: {
        source: [...remainingSourceItems],
        target: [...items.target, ...movedSourceItems],
      },
    });
  };

  return (
    <div className='flex flex-col items-center gap-4'>
      {/* move source items to target */}
      <Button
        icon='pi pi-angle-right'
        severity={SEVERITY}
        disabled={moveToTargetDisabled}
        onClick={handleMoveToTarget}
      />

      {/* move all source items to target */}
      <Button
        icon='pi pi-angle-double-right'
        severity={SEVERITY}
        disabled={moveAllToTargetDisabled}
        onClick={handleMoveAllToTarget}
      />

      {/* move target items to source */}
      <Button
        icon='pi pi-angle-left'
        severity={SEVERITY}
        disabled={MoveToSourceDisabled}
        onClick={handleMoveToSource}
      />

      {/* move all target items to source */}
      <Button
        icon='pi pi-angle-double-left'
        severity={SEVERITY}
        disabled={MoveAllToSourceDisabled}
        onClick={handleMoveAllToSource}
      />
    </div>
  );
}
