import { useAuth } from '../../features/auth';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DocumentView from './components/DocumentView';
import { retrieveSessionTokenFromSessionStorage } from '../../api';
import SideBar from './components/Sidebar';
import { Button } from 'primereact/button';
import { useTemplates } from '../../features/templates';
import useTemplateUI from '../../features/templates/hooks/useTemplatesUI';
import usePropertiesUi from '../../features/properties/hooks/usePropertiesUi';
import PropertiesPage from '../Properties';

const DashboardView = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const isInitialRender = useRef(true);

  const navigate = useNavigate();
  const { selectedTemplate } = useTemplates();
  const { setTemplatesModalVisibility } = useTemplateUI();
  const { user } = useAuth();
  const { propertiesViewVisible } = usePropertiesUi();

  useEffect(() => {
    if (isInitialRender.current && !selectedTemplate) {
      isInitialRender.current = false;
      setTemplatesModalVisibility(true);
    }
  }, [selectedTemplate, setTemplatesModalVisibility]);

  // Redirect to login if user or token is not present. This happens when a user navigates to an external page within the app.
  useEffect(() => {
    if (!retrieveSessionTokenFromSessionStorage() || !user) {
      navigate('/');
    }
  }, [user, navigate]);

  return (
    <>
      <div className={`${propertiesViewVisible ? 'h-0' : 'h-screen'} w-screen flex bg-primary-50 overflow-hidden`}>
        <div
          className='overflow-hidden h-screen overflow-y-auto bg-white'
          style={{ flex: isSidebarOpen ? '0 0 300px' : '0 0 0 ' }}
        >
          <SideBar />
        </div>

        <div className='flex-1 h-screen w-full'>
          <DocumentView />
        </div>
      </div>
      <div
        className={`${propertiesViewVisible ? 'h-screen' : 'h-0'} absolute w-screen bg-white overflow-hidden z-[1000]`}
      >
        <PropertiesPage />
      </div>

      {/* close sidebar button */}
      {!propertiesViewVisible && (
        <Button
          className={`absolute bottom-3 block bg-white !shadow-none !outline-none !border-none  text-neutral-300 hover:text-neutral-500 rounded-md ${
            isSidebarOpen ? 'left-[295px]' : '-left-3'
          }`}
          icon={`pi pi-angle-double-${isSidebarOpen ? 'left' : 'right'}`}
          onClick={() => setIsSidebarOpen(() => !isSidebarOpen)}
        />
      )}
    </>
  );
};

export default DashboardView;
