import { PropertiesTable, PropertiesControlHeader } from '../../features/properties';
import { Button } from 'primereact/button';
import usePropertiesUi from '../../features/properties/hooks/usePropertiesUi';

export default function PropertiesPage() {
  const { setPropertiesViewVisible } = usePropertiesUi();

  return (
    <div className='w-full h-screen flex flex-col gap-2'>
      {/* header */}
      <div className='flex items-center justify-between flex-initial w-full gap-4'>
        {/* back button */}
        <Button
          size='small'
          tooltip='Back'
          tooltipOptions={{ showDelay: 1000 }}
          icon='pi pi-arrow-left'
          onClick={() => setPropertiesViewVisible(false)}
          outlined
        />

        <div className='w-[1px] h-[70%] bg-slate-300' />

        <PropertiesControlHeader />
      </div>

      {/* body */}
      <PropertiesTable />
    </div>
  );
}
