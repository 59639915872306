import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export interface SortableProps {
  id: string;
  children: React.ReactNode;
  data?: any;
  disabled?: boolean;
}

export function Sortable({ id, children, data, disabled }: SortableProps) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
    data,
    disabled: disabled || false,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} className='w-full' style={style} {...attributes} {...listeners}>
      {children}
    </div>
  );
}
