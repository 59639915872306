import { useCallback, useMemo } from 'react';
import { useLoginMutation } from '../authApi';
import { selectToken, selectUser, setUser as _setUser } from '../authSlice';
import { useSessionStorage } from 'primereact/hooks';

import type { User } from '../authApi';
import { useDispatch, useSelector } from 'react-redux';

export interface UseAuthReturnType {
  user: User | null;
  getUser: (token: string) => Promise<User | undefined>;
  setUser: (user: User) => void;
}

export function useAuth(): UseAuthReturnType {
  const [login] = useLoginMutation();
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);
  const storeUserInSession = useSessionStorage<User | null>(null, 'sessionToken')[1];

  const dispatch = useDispatch();

  // Set user in session storage and redux store
  const setUser = useCallback(
    (user: User) => {
      dispatch(_setUser(user));
      storeUserInSession(user);
    },
    [dispatch, storeUserInSession],
  );

  // Get user by logging in with the token
  const getUser = useCallback(
    async (token: string) => {
      const userData = await login({ sessionToken: token }).unwrap();

      // Check if the properties are present in userData. If not then there was an error in the login
      if (!('objectId' in userData) || !('objectType' in userData) || !('isAdmin' in userData)) return undefined;

      // Return the user data
      return { objectId: userData.objectId, objectType: userData.objectType, isAdmin: userData.isAdmin || false };
    },
    [login],
  );

  return useMemo(
    () => ({
      user,
      token,

      setUser,
      getUser,
    }),
    [getUser, user, token, setUser],
  );
}

export default useAuth;
